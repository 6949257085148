import { Component, OnInit } from '@angular/core';
import { JobData, JobDataService } from '../job-data.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})
export class CompleteComponent implements OnInit {

  jobData: JobData = new JobData();
  problemDescription: string = '';
  shortDate: string = '';
  timeWindowString: string = '';
  serviceType: string = '';
  selectedPriority: string = '';

  usesPriorityNotDateTime: boolean = environment.usesPriorityNotDateTime;

  constructor(private jobDataService: JobDataService, private utilityService: UtilityService) { }

  ngOnInit(): void {

    // fetch cached job data from service
    this.jobData = this.jobDataService.getJobData();

    // load service type
    if (this.jobData.services[0]) {
      this.serviceType = this.jobData.services[0].externalServiceID;
    }

    // load problem description
    this.problemDescription = this.jobDataService.getProblemDescription();

    // load priority or appointment window
    if (this.usesPriorityNotDateTime) {
      this.selectedPriority = this.utilityService.convertPriorityToString(this.jobDataService.getSelectedPriority());
    } else {
      this.shortDate = this.jobDataService.getShortDate();
      this.timeWindowString = this.jobDataService.getTimeWindowString();
    }

  }

  getCompleteAddressString(): string {
    return this.jobData.streetAddress + ', ' + this.jobData.suburbName + ', ' + this.jobData.postcode;
  }

  getJobReference(): string {
    return `${this.jobDataService.jobReference || ''}`;
  }

}
