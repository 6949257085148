import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Clients } from '../globals.service';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrls: ['./home-default.component.css']
})
export class HomeDefaultComponent implements OnInit {

  constructor(public clients: Clients) { }

  clientID = environment.clientID;
  
  scroll(id: string) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({behavior: 'smooth'});
    }
  }

  ngOnInit(): void {
  }

}
