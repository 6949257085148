import { Injectable } from '@angular/core';
import { FirestoreDataService } from './firestore-data.service';
import { HomeServiceItem, InclusionData, JobBookingWindow, JobContact, JobData, TriageResponseItem } from './job-data.service';
import { UtilityService } from './utility.service';
import { FormFieldTypes, GlobalsService, JobChannelType, JobNoteTypes, PriorityTypes } from './globals.service';
import { HttpClient } from '@angular/common/http';
import { DropdownService } from './dropdown.service';

class TestBookingPhoto {
  data: Blob | null = null;
  name: string = 'Test From job-impersonator.';
  base64: string = '';
  loaded: boolean = true;

  constructor() {
  }
}

class TestTriageResponseItem {

  public questionTypeID: number = 0;
  public noteTypeID: number = -1;
  public triageAnswer: string = '';
  public triageFullAnswer: string = '';
  public triageQuestion: string = '';
  public showOnSOW: boolean = false;
  public inclusionData: InclusionData | null = null;

  constructor() { }
}

@Injectable({
  providedIn: 'root'
})
export class JobImpersonatorService {

  dummySupervisorEmails = 'mmeskell@johnslyng.com.au';
  dummyImageData: string = '';

  constructor(
    private firestoreService: FirestoreDataService,
    private utils: UtilityService,
    private http: HttpClient,
    private ddlService: DropdownService
  ) {
    this.http.get('assets/test-data/yeap-base64-data_compressed.txt', { responseType: 'text' }).subscribe(data => this.dummyImageData = data);
  }


  async TriggerTestJob(priorityType: number) {

    var testJob = this.getMockJob(priorityType);
    var testPhoto = new TestBookingPhoto();
    testPhoto.base64 = this.dummyImageData;
    var bookingPhotos: Array<TestBookingPhoto> = [testPhoto];

    await this.firestoreService.createJob(
      testJob,
      bookingPhotos,
      priorityType,
      this.dummySupervisorEmails,
      'A Very Real Business Inc'
    );
  }

  getMockJob(priorityType: number): JobData {
    var job = new JobData();
    job.customerReference = 'WL-Lite Job Impersonator';

    job.externalJobID = '';
    job.jobReference = '';

    // spoof address
    job.streetUnit = '42';
    job.streetAddress = '1 Williamsons Road'
    job.suburbName = 'Doncaster';
    job.postcode = '3108';

    // todo: use venue anything?

    // todo:
    // testJob.businessName

    job.description = "Test job generated by job-impersonator.service.";

    // generate booking window
    var bookingWindow = new JobBookingWindow();
    var date = new Date();
    date.setDate(date.getDate() + 14);  // this cant be needed, surely?
    bookingWindow.date = date;
    bookingWindow.time = this.utils.getTimeWindow('12');

    // add booking window to
    job.bookingWindows.push(bookingWindow);

    // add contact to job data
    var jobContact = new JobContact();

    jobContact.contactEmail = "development@easylinkservices.com.au";
    jobContact.contactMobile = "0400 000 000";    // todo: real test number
    jobContact.contactPhone = "6300 0000";        // todo: real test number
    jobContact.contactName = "Ms Automated Test - " + this.utils.convertPriorityToString(priorityType);

    // comment these in as needed for testing
    // dummyContact.contactEmail += "mmeskell@johnslyng.com.au"; 
    // dummyContact.contactEmail += "jgill@johnslyng.com.au"; 
    // dummyContact.contactEmail += "sbertolin@johnslyng.com.au"; 
    // dummyContact.contactEmail += "ajohn@johnslyng.com.au"; 

    job.customerContact = jobContact;
    job.siteContact = jobContact;

    // add services info to job data
    job.services = [];
    var mockHomeServiceItem = new HomeServiceItem();
    var serviceTypes = this.ddlService.getUnfilteredDDL(FormFieldTypes.ServiceType);
    if (serviceTypes[0]) {
      mockHomeServiceItem.externalServiceID = serviceTypes[0].key;
    } else {
      mockHomeServiceItem.externalServiceID = 'General Handyman';
    }
    mockHomeServiceItem.priceMarkup = 0;
    mockHomeServiceItem.discount = 0;
    mockHomeServiceItem.estimatedDuration = 1;
    mockHomeServiceItem.estimatedPrice = 1;

    // TRIAGE RESPONSES
    var mockResponses: Array<TestTriageResponseItem> = [];

    // booking channel response
    var bookingChannelResponse = new TestTriageResponseItem();
    bookingChannelResponse.questionTypeID = 0;
    bookingChannelResponse.noteTypeID = -1; // add real id
    bookingChannelResponse.triageQuestion = 'Booking Channel';  // is this a magic string?
    bookingChannelResponse.triageAnswer = JobChannelType[JobChannelType.ONLINE];       // what does this map to?
    bookingChannelResponse.triageFullAnswer = JobChannelType[JobChannelType.ONLINE];    // and also this?
    bookingChannelResponse.showOnSOW = false; // what is?
    mockResponses.push(bookingChannelResponse);

    // date/time response
    const dateTimeAnswerString = bookingWindow.date.toDateString() + ' ' + bookingWindow.time.toString();
    var bookingWindowResponse = new TestTriageResponseItem();
    bookingWindowResponse.questionTypeID = 0;
    bookingWindowResponse.noteTypeID = -1; // add real id
    bookingWindowResponse.triageQuestion = 'Booking Window'; // is this a magic string?
    bookingWindowResponse.triageAnswer = dateTimeAnswerString;
    bookingWindowResponse.triageFullAnswer = dateTimeAnswerString; // should this be different?
    bookingWindowResponse.showOnSOW = false; // what is?
    mockResponses.push(bookingWindowResponse);

    // description of works response
    var descriptionOfWorksResponse = new TestTriageResponseItem();
    descriptionOfWorksResponse.questionTypeID = 0;
    descriptionOfWorksResponse.noteTypeID = JobNoteTypes.JobDescription;
    descriptionOfWorksResponse.triageQuestion = 'Description of works to be done:'; // make better
    descriptionOfWorksResponse.triageAnswer = 'This is the works description (short answer).';
    descriptionOfWorksResponse.triageFullAnswer = 'This is the works description (full answer).';
    descriptionOfWorksResponse.showOnSOW = false; // what is?
    mockResponses.push(descriptionOfWorksResponse);
    // mockHomeServiceItem.triageResponses.push(descriptionOfWorksResponse);

    // priority type response
    const priorityTypeAnswerString = this.utils.convertPriorityToString(priorityType);
    var priorityTypeResponse = new TestTriageResponseItem();
    priorityTypeResponse.questionTypeID = 0;
    priorityTypeResponse.noteTypeID = JobNoteTypes.JobDescription;
    priorityTypeResponse.triageQuestion = 'Customer Indication of Priority'; // make better
    priorityTypeResponse.triageAnswer = priorityTypeAnswerString + '(short answer)';
    priorityTypeResponse.triageFullAnswer = priorityTypeAnswerString + ' (full answer).';
    priorityTypeResponse.showOnSOW = false; // what is?
    mockResponses.push(priorityTypeResponse);

    // convert mock item to real item (doing this because the TriageResponseItem constructor is messy/confusing)
    mockHomeServiceItem.triageResponses = [];
    mockResponses.forEach(response => {
      mockHomeServiceItem.triageResponses.push(
        new TriageResponseItem(
          response.questionTypeID,
          response.triageAnswer,
          response.triageQuestion,
          response.triageFullAnswer,
          response.noteTypeID,
          response.showOnSOW
        ))
    });

    job.services.push(mockHomeServiceItem);
    return job;
  }

}

