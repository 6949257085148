import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormControlOptions, FormGroup, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { QuestionBase } from './forms/question-base';
import { InputFieldGroup } from './question.service';
import { CustomValidationTypes } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {

  constructor() { }

  // new version of formGroup generator function, with custom validation. added 2023-04-21 - MM 
  toFormGroup(fieldGroups: InputFieldGroup[]) {

    const group: any = {};
    var setMustMatch = false;
    var controlPairsToMatch: { control: string, controlToMatch: string }[] = [];

    var businessNameMaximumLength = 100;

    fieldGroups.forEach(fieldGroup => {
      fieldGroup.questionRows.forEach(row => {
        row.forEach(question => {

          const hasCustomValidation = question.validationType && question.validationType.length > 0;
          const mustMatchAnotherField = question.mustMatchField && question.mustMatchField.length > 0;
          const validators: ValidatorFn[] = [];

          if (question.required) {
            validators.push(Validators.required);
          }

          if (hasCustomValidation) {
            switch (question.validationType) {
              case CustomValidationTypes.Email:
                validators.push(Validators.email);
                break;
              case CustomValidationTypes.businessNameMaxLength:
                validators.push(Validators.maxLength(businessNameMaximumLength));
                break;
              default:
                break;
            }
          }

          if (mustMatchAnotherField) {
            // because we're comparing multiple fields, we need to add this validator to the group at the end, not to the control
            setMustMatch = true;
            controlPairsToMatch.push({ control: question.key, controlToMatch: question.mustMatchField });
          }

          let control: FormControl = validators.length > 0 ? new FormControl(question.value || '', validators) : new FormControl(question.value || '');

          if (question.isDisabled)
            control.disable();

          group[question.key] = control;

        });
      });
    });

    var formGroup: FormGroup;

    if (setMustMatch) {
      // add formGroup level validators
      const validators: ValidatorFn[] = [];
      controlPairsToMatch.forEach(pair => {
        validators.push(MustMatch(pair.control, pair.controlToMatch));
      })
      formGroup = new FormGroup(group, { validators: validators })
    } else {
      formGroup = new FormGroup(group);
    }

    return formGroup;
  }

}


export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {

  // 2023-04-21 - MM 
  // custom validator to check if two form fields contain the same value
  // todo: not sure if this should belong elsewhere. if we make another custom validation function, probably refactor both out to a service.

  return (form: AbstractControl): ValidationErrors | null => {

    const control = form.get(controlName);
    const matchingControl = form.get(matchingControlName);

    // if controls are missing, don't attempt to match
    if (!control || !matchingControl) {
      return null;
    }

    // return if another validator has already found an error on the other control
    if ((matchingControl.errors && !matchingControl.errors['mustMatch']) || (control.errors && !control.errors['mustMatch'])) {
      return null;
    }

    // set or remove errors depending on whether the values match
    if (control.value !== matchingControl.value) {
      control.setErrors({ mustMatch: true });
      matchingControl.setErrors({ mustMatch: true });
      return { mustMatch: true }
    } else {
      control.setErrors(null);
      matchingControl.setErrors(null);
    }

    return null
  }

}