import { Injectable } from '@angular/core';
import { PriorityTypes } from './globals.service';
import { JobTimeWindow } from './job-data.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(public priorityTypes: PriorityTypes) { }

  convertPriorityToString(priority: number): string {
    var output = 'No Priority Set';

    switch (priority) {
      case this.priorityTypes.Emergency:
        output = 'Emergency';
        break;
      case this.priorityTypes.MediumPriority:
        output = 'Medium Priority';
        break;
      case this.priorityTypes.LowPriority:
        output = 'Low Priority';
        break;
    }

    return output;
  }

  convertToTitleCase(input: string) {
    if (input.length > 3) {
      return input.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }

    return input;
  }

  getBrandNameFromAbbreviation(abbreviation: string): string {

    var fullName: string;

    switch (abbreviation.toUpperCase()) {
      case 'MH':
        fullName = 'MyHouse';
        break;
      case 'HSE':
        fullName = 'House';
        break;
      case 'RK':
        fullName = 'Robins Kitchen';
        break;
      case 'SUPER':
        fullName = 'House Super';
        break;
      case 'HBB':
        fullName = 'House Bed & Bath';
        break;
      case '5STAR':
        fullName = 'Five Star Factory Direct';
        break;
      default:
        fullName = abbreviation;
        break;
    }

    return fullName;

  }

  getAMPMString(militaryTime: number): string {
   
    // determine whether time is am or pm
    var suffix = '';
    if (militaryTime < 12 || militaryTime == 24) {
      suffix = 'am';
    } else {
      suffix = 'pm';
    }
    
    // convert 24 hour time to 12 hour time
    let ampmTime = militaryTime % 12;

    // if time was a multiple of 12, make it 12 instead of 0.
    if (ampmTime == 0) {
      ampmTime = 12;
    }

    return ampmTime + ':00' + suffix;
  }

  getTimeWindow(startTime: string): JobTimeWindow {

    var window: JobTimeWindow = new JobTimeWindow();

    // get from and to times as numbers
    let fromTimeAsNum = +startTime;
    let toTimeAsNum = fromTimeAsNum + 2;

    // temporarily hard-coding windows to avoid having to update Time Slots in Easylink (EL currently prevents overnight bookings)
    window.from = this.getAMPMString(12);
    window.to = this.getAMPMString(14);
    // window.from = this.getAMPMString(fromTimeAsNum);
    // window.to = this.getAMPMString(toTimeAsNum);

    return window;

  }
}
