import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IJobSite } from './firestore-data.service';
import { Clients, FormFieldTypes } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  // environment variables
  clientID: number = environment.clientID;
  activeDDLOptions: any = environment.activeDDLOptions;

  // DDL data
  jobSiteList: IJobSite[] = [];

  unfilteredJobSiteNames: DropdownItem[] = [];
  unfilteredCountries: DropdownItem[] = [];
  unfilteredBrands: DropdownItem[] = [];
  unfilteredRegions: DropdownItem[] = [];
  unfilteredServices: DropdownItem[] = [];
  unfilteredClubTypes: DropdownItem[] = [];
  unfilteredBuildingNames: DropdownItem[] = [];
  unfilteredLotNumbers: DropdownItem[] = [];

  filteredJobSiteNames: DropdownItem[] = [];
  filteredCountries: DropdownItem[] = [];
  filteredBrands: DropdownItem[] = [];
  filteredRegions: DropdownItem[] = [];
  filteredServices: DropdownItem[] = [];
  filteredClubTypes: DropdownItem[] = [];
  filteredBuildingNames: DropdownItem[] = [];
  filteredLotNumbers: DropdownItem[] = [];

  serviceDescriptions: DropdownItem[] = [];

  constructor(
    public clients: Clients
  ) { }

  public loadDDLs(_jobSiteList: IJobSite[]) {
    this.jobSiteList = _jobSiteList;

    // set up job site DDLs
    if (this.activeDDLOptions[FormFieldTypes.JobSite]) {
      const jobSiteNames = [...new Set(this.jobSiteList.map(item => item.name))].sort((a, b) => a.localeCompare(b));
      const jobSiteNamesOptions = this.arrayToOptions(jobSiteNames);
      this.unfilteredJobSiteNames = jobSiteNamesOptions;
      this.filteredJobSiteNames = jobSiteNamesOptions;
    }

    // set up region DDLs
    if (this.activeDDLOptions[FormFieldTypes.Region]) {
      const regionNames = [...new Set(this.jobSiteList.map(item => item.state))].sort((a, b) => a.localeCompare(b));
      const regionNamesOptions = this.arrayToOptions(regionNames);
      this.unfilteredRegions = regionNamesOptions;
      this.filteredRegions = regionNamesOptions;
    }

    // set up country DDLs
    if (this.activeDDLOptions[FormFieldTypes.Country]) {
      const countries = [...new Set(this.jobSiteList.map(item => item.country))].sort((a, b) => a.localeCompare(b));
      const countriesOptions = this.arrayToOptions(countries);
      this.unfilteredCountries = countriesOptions;
      this.filteredCountries = countriesOptions;
    }

    // set up brand DDLs
    if (this.activeDDLOptions[FormFieldTypes.Brand]) {
      const brands = [...new Set(this.jobSiteList.map(item => item.brand))].sort((a, b) => a.localeCompare(b));
      const brandsOptions = this.arrayToOptions(brands);
      this.unfilteredBrands = brandsOptions;
      this.filteredBrands = brandsOptions;
    }

    // set up Club Type DDLs
    if (this.activeDDLOptions[FormFieldTypes.ClubType]) {
      const clubTypes = [...new Set(this.jobSiteList.map(item => item.clubType))].sort((a, b) => a.localeCompare(b));
      const clubTypesOptions = this.arrayToOptions(clubTypes);
      this.unfilteredClubTypes = clubTypesOptions;
      this.filteredClubTypes = clubTypesOptions;
    }

    // set up buildingName DDLs
    if (this.activeDDLOptions[FormFieldTypes.BuildingName]) {
      const buildingNames = [...new Set(this.jobSiteList.map(item => item.buildingName))].sort((a, b) => a.localeCompare(b));
      const buildingNamesOptions = this.arrayToOptions(buildingNames);
      this.unfilteredBuildingNames = buildingNamesOptions;
      this.filteredBuildingNames = buildingNamesOptions;
    }

    // set up lotNumber DDLs
    if (this.activeDDLOptions[FormFieldTypes.LotNumber]) {
      const lotNumbers = [...new Set(this.jobSiteList.map(item => item.lotNumber.toString()))].sort((a, b) => a.localeCompare(b));
      const lotNumberOptions = this.arrayToOptions(lotNumbers);
      this.unfilteredLotNumbers = lotNumberOptions;
      this.filteredLotNumbers = lotNumberOptions;
    }

    // set up service type DDL
    if (this.activeDDLOptions[FormFieldTypes.ServiceType]) {
      this.loadServiceTypes();
    }
  }

  public getUnfilteredDDL(key: FormFieldTypes): DropdownItem[] {
    switch (key) {
      case FormFieldTypes.Brand:
        return this.unfilteredBrands;
      case FormFieldTypes.Country:
        return this.unfilteredCountries;
      case FormFieldTypes.Region:
        return this.unfilteredRegions;
      case FormFieldTypes.ServiceType:
        return this.unfilteredServices;
      case FormFieldTypes.JobSite:
        return this.unfilteredJobSiteNames;
      case FormFieldTypes.ClubType:
        return this.unfilteredClubTypes;
      case FormFieldTypes.BuildingName:
        return this.unfilteredBuildingNames;
      case FormFieldTypes.LotNumber:
        return this.unfilteredLotNumbers;
      default:
        return [];
    }
  }

  public getFilteredDDL(key: FormFieldTypes): DropdownItem[] {
    switch (key) {
      case FormFieldTypes.Brand:
        return this.filteredBrands;
      case FormFieldTypes.Country:
        return this.filteredCountries;
      case FormFieldTypes.Region:
        return this.filteredRegions;
      case FormFieldTypes.ServiceType:
        return this.filteredServices;
      case FormFieldTypes.JobSite:
        return this.filteredJobSiteNames;
      case FormFieldTypes.ClubType:
        return this.filteredClubTypes;
      case FormFieldTypes.BuildingName:
        return this.filteredBuildingNames;
      case FormFieldTypes.LotNumber:
        return this.filteredLotNumbers;
      default:
        return [];
    }
  }

  public getServiceTypeDescriptions() {
    return this.serviceDescriptions;
  }

  public loadServiceTypes() {

    var services: DropdownItem[] = [];

    switch (this.clientID) {
      case this.clients.TheBodyShop:
        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Electrical',
          value: 'Ceiling lights out.'
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        services.push({
          key: 'Shopfront Door',
          value: 'Roller door, locks etc.'
        });
        services.push({
          key: 'BB Sink',
          value: 'Workshop stores only.'
        });
        services.push({
          key: 'Other General Maintenance',
          value: 'Any general maintenance issues. A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Other Services Maintenance',
          value: 'Any general services maintenance issues, mechanical, fire etc - A complete description can be provided in the final step of this form.'
        });

        break;

      case this.clients.TheBodyShopNZ:
        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Joinery/Fixtures',
          value: ''
        });
        services.push({
          key: 'Electrical',
          value: 'Ceiling lights out / Pulse backlit bay lights out.'
        });
        services.push({
          key: 'Lighting',
          value: 'Ceiling lights out / Pulse backlit bay lights out. Note: A minimum of 3 ceiling lights must be out before a maintenance request is submitted.'
        });
        services.push({
          key: 'Signage',
          value: ''
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Mechanical/HVAC',
          value: ''
        });
        services.push({
          key: 'Doors/Door Hardware',
          value: 'Front main door repairs, Roller door, locks etc.'
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        services.push({
          key: 'Flooring',
          value: 'Shop floor repairs including wooden or tiled floors.'
        });
        services.push({
          key: 'Glass /Glazing/ Mirrors',
          value: 'Glass, glazing and mirror repairs.'
        });
        services.push({
          key: 'Painting',
          value: 'Painting doors, walls, windows, artifacts, etc.'
        });
        services.push({
          key: 'Cleaning',
          value: ''
        });
        services.push({
          key: 'Shopfront',
          value: ''
        }); services.push({
          key: 'POS Counter',
          value: ''
        });
        services.push({
          key: 'Window Cleaning',
          value: 'Cleaning all kinds of windows and glass.'
        });
        services.push({
          key: 'Pest Control',
          value: 'Any service involving pests and rodents.'
        });
        services.push({
          key: 'Minor Project Works',
          value: ''
        });
        services.push({
          key: 'Other General Maintenance',
          value: 'Any general maintenance issues. A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Other Services Maintenance',
          value: 'Any general services maintenance issues, mechanical, fire etc - A complete description can be provided in the final step of this form.'
        });
        break;

      case this.clients.VIVA:
        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Electrical',
          value: 'Ceiling lights out / Pulse backlit bay lights out.'
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        services.push({
          key: 'Door Maintenance',
          value: 'Front main door repairs, Roller door, locks etc.'
        });
        services.push({
          key: 'Flooring',
          value: 'Shop floor repairs including wooden or tiled floors.'
        });
        services.push({
          key: 'Other General Maintenance',
          value: 'Any general maintenance issues.'
        });
        services.push({
          key: 'Other Services Maintenance',
          value: 'Any general services maintenance issues, mechanical, fire etc - A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Test and Tag',
          value: 'Involves visually inspecting and testing each appliance for damage to flex, connections, guards, covers and plug by a competent technician.'
        });
        services.push({
          key: 'Window Cleaners',
          value: 'Cleaning all kinds of windows and glass'
        });
        services.push({
          key: 'Painting',
          value: 'Painting doors, walls, windows, artifacts, etc.'
        });
        services.push({
          key: 'Pest Control',
          value: 'Any service involving pests and rodents.'
        });
        services.push({
          key: 'Roof and Gutter Repair',
          value: 'Roof and gutter repairs.'
        });
        services.push({
          key: 'Steam and Sauna Service',
          value: 'Steam and sauna service and repair needs.'
        });
        services.push({
          key: 'Glass/Glazing/Mirrors Service',
          value: 'Glass, glazing and mirror repairs.'
        });
        services.push({
          key: 'Gardener',
          value: 'Any gardening service and general garden maintenance.'
        });

        break;

      case this.clients.LIDS:

        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Electrical',
          value: 'Replace switches or install new power points. Ceiling fan or exhaust fan installs or replacement. Install or replace smoke alarms and internet/data ports.'
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        services.push({
          key: 'Shopfront',
          value: ''
        });
        services.push({
          key: 'Doors/Door Hardware',
          value: 'All door repairs'
        });
        services.push({
          key: 'Joinery/Fixtures',
          value: ''
        });
        services.push({
          key: 'Other General Maintenance',
          value: 'Any general maintenance issues. A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Other Services Maintenance',
          value: 'Any general services maintenance issues, mechanical, fire etc - A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Pest Control',
          value: 'Any service involving pests and rodents.'
        });
        services.push({
          key: 'Window Cleaning',
          value: 'Windows cleaning services.'
        });
        services.push({
          key: 'POS Counter',
          value: ''
        });
        services.push({
          key: 'Cleaning',
          value: ''
        });
        services.push({
          key: 'Minor Project Works',
          value: ''
        });
        services.push({
          key: 'Painting',
          value: 'Painting doors, walls, windows, artifacts, etc.'
        });
        services.push({
          key: 'Glass/Glazing/Mirrors',
          value: 'Glass repairs, glazing services and mirror replacement and installation services.'
        });
        services.push({
          key: 'Flooring',
          value: 'Shop floor repairs including wooden or tiled floors.'
        });
        services.push({
          key: 'Mechanical/HVAC',
          value: ''
        });
        services.push({
          key: 'Signage',
          value: ''
        });
        services.push({
          key: 'Lighting',
          value: ''
        });

        break;

      case this.clients.GlobalRetailBrands:
        services.push({
          key: 'General Trade / Other',
          value: 'Hanging pictures, light bulb change, furniture assembly, plastering work, balcony / deck staining.'
        });
        services.push({
          key: 'Plumbing and Gas',
          value: 'Supply and replace taps or fix a drip or a burst pipe. Hot water service, dishwasher replacements and unblock toilets or drains.'
        });
        services.push({
          key: 'Electrical',
          value: 'Replace switches or install new power points. Ceiling fan or exhaust fan installs or replacement. Install or replace smoke alarms and internet/data ports.'
        });
        services.push({
          key: 'Glazing',
          value: 'Shower screens, Window replacements, Glass Repairs, Splashbacks, Glass Mirrors'
        });
        services.push({
          key: 'Carpentry',
          value: 'Door Handles, Hanging hooks, Wall repairs, Skirting board repairs, Kitchen Cabinetry, Deck/Patio Repairs'
        });
        services.push({
          key: 'Heating / Cooling',
          value: 'Gas heater, split system air conditioners, ducted evaporating or refrigerated ducted air conditioner service and repairs.'
        });
        services.push({
          key: 'Roof Plumbing',
          value: 'Roof leaks, roof tiles, silicone works and gutter cleaning / replacements. Custom made flashings, box gutters, roof painting or down pipe installations.'
        });
        services.push({
          key: 'Fencing',
          value: 'Timber fencing, Colorbond Fencing, Aluminium & Steel fencing, Fence gates installation, Retaining Walls and more'
        });

        break;
      case this.clients.DerrimutGyms:
        services.push({
          key: 'General Trade / Other',
          value: 'Hanging pictures, light bulb change, furniture assembly, plastering work, balcony / deck staining.'
        });
        services.push({
          key: 'Plumbing and Gas',
          value: 'Supply and replace taps or fix a drip or a burst pipe. Hot water service, dishwasher replacements and unblock toilets or drains.'
        });
        services.push({
          key: 'Electrical',
          value: 'Replace switches or install new power points. Ceiling fan or exhaust fan installs or replacement. Install or replace smoke alarms and internet/data ports.'
        });
        services.push({
          key: 'Glazing',
          value: 'Shower screens, Window replacements, Glass Repairs, Splashbacks, Glass Mirrors'
        });
        services.push({
          key: 'Carpentry',
          value: 'Door Handles, Hanging hooks, Wall repairs, Skirting board repairs, Kitchen Cabinetry, Deck/Patio Repairs'
        });
        services.push({
          key: 'Heating / Cooling',
          value: 'Gas heater, split system air conditioners, ducted evaporating or refrigerated ducted air conditioner service and repairs.'
        });
        services.push({
          key: 'Roof Plumbing',
          value: 'Roof leaks, roof tiles, silicone works and gutter cleaning / replacements. Custom made flashings, box gutters, roof painting or down pipe installations.'
        });

        break;

      case this.clients.AHA:
        services.push({
          key: 'General Trade / Other',
          value: 'Hanging pictures, light bulb change, furniture assembly, plastering work, balcony / deck staining.'
        });
        services.push({
          key: 'Plumbing and Gas',
          value: 'Supply and replace taps or fix a drip or a burst pipe. Hot water service, dishwasher replacements and unblock toilets or drains.'
        });
        services.push({
          key: 'Electrical',
          value: 'Replace switches or install new power points. Ceiling fan or exhaust fan installs or replacement. Install or replace smoke alarms and internet/data ports.'
        });
        services.push({
          key: 'Glazing',
          value: 'Shower screens, Window replacements, Glass Repairs, Splashbacks, Glass Mirrors'
        });
        services.push({
          key: 'Carpentry',
          value: 'Door Handles, Hanging hooks, Wall repairs, Skirting board repairs, Kitchen Cabinetry, Deck/Patio Repairs'
        });
        services.push({
          key: 'Heating / Cooling',
          value: 'Gas heater, split system air conditioners, ducted evaporating or refrigerated ducted air conditioner service and repairs.'
        });
        services.push({
          key: 'Roof Plumbing',
          value: 'Roof leaks, roof tiles, silicone works and gutter cleaning / replacements. Custom made flashings, box gutters, roof painting or down pipe installations.'
        });
        break;

      case this.clients.OneTouchDirect:
        services.push({
          key: 'General Trade / Other',
          value: 'Hanging pictures, light bulb change, furniture assembly, plastering work, balcony / deck staining.'
        });
        services.push({
          key: 'Plumbing and Gas',
          value: 'Supply and replace taps or fix a drip or a burst pipe. Hot water service, dishwasher replacements and unblock toilets or drains.'
        });
        services.push({
          key: 'Electrical',
          value: 'Replace switches or install new power points. Ceiling fan or exhaust fan installs or replacement. Install or replace smoke alarms and internet/data ports.'
        });
        services.push({
          key: 'Glazing',
          value: 'Shower screens, Window replacements, Glass Repairs, Splashbacks, Glass Mirrors'
        });
        services.push({
          key: 'Carpentry',
          value: 'Door Handles, Hanging hooks, Wall repairs, Skirting board repairs, Kitchen Cabinetry, Deck/Patio Repairs'
        });
        services.push({
          key: 'Heating / Cooling',
          value: 'Gas heater, split system air conditioners, ducted evaporating or refrigerated ducted air conditioner service and repairs.'
        });
        services.push({
          key: 'Roof Plumbing',
          value: 'Roof leaks, roof tiles, silicone works and gutter cleaning / replacements. Custom made flashings, box gutters, roof painting or down pipe installations.'
        });
        services.push({
          key: 'Fencing',
          value: 'Fencing Service'
        });
        break;

      case this.clients.EasyLink:
        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Electrical',
          value: 'Ceiling lights out / Pulse backlit bay lights out.'
        });
        services.push({
          key: 'Lighting',
          value: 'Ceiling lights out / Pulse backlit bay lights out. Note: A minimum of 3 ceiling lights must be out before a maintenance request is submitted.'
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Air Conditioning',
          value: ''
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        break;

      case this.clients.RRRDW:
        services.push({
          key: 'General Handyman',
          value: 'Broken fixtures, back of house door hinges, broken drawers/runners, etc.'
        });
        services.push({
          key: 'Joinery/Fixtures',
          value: ''
        });
        services.push({
          key: 'Electrical',
          value: 'Ceiling lights out / Pulse backlit bay lights out.'
        });
        services.push({
          key: 'Lighting',
          value: 'Ceiling lights out / Pulse backlit bay lights out. Note: A minimum of 3 ceiling lights must be out before a maintenance request is submitted.'
        });
        services.push({
          key: 'Signage',
          value: ''
        });
        services.push({
          key: 'Plumbing',
          value: 'Back of house sink tap/plumbing.'
        });
        services.push({
          key: 'Mechanical/HVAC',
          value: ''
        });
        services.push({
          key: 'Doors/Door Hardware',
          value: 'Front main door repairs, Roller door, locks etc.'
        });
        services.push({
          key: 'Locksmith',
          value: 'Back of house lock/keypad.'
        });
        services.push({
          key: 'Flooring',
          value: 'Shop floor repairs including wooden or tiled floors.'
        });
        services.push({
          key: 'Glass /Glazing/ Mirrors',
          value: 'Glass, glazing and mirror repairs.'
        });
        services.push({
          key: 'Painting/Plastering',
          value: 'Painting doors, walls, windows, artifacts, etc.'
        });
        services.push({
          key: 'Cleaning',
          value: ''
        });
        services.push({
          key: 'Shopfront',
          value: ''
        }); services.push({
          key: 'POS Counter',
          value: ''
        });
        services.push({
          key: 'Window Cleaning',
          value: 'Cleaning all kinds of windows and glass.'
        });
        services.push({
          key: 'Pest Control',
          value: 'Any service involving pests and rodents.'
        });
        services.push({
          key: 'Minor Project Works',
          value: ''
        });
        services.push({
          key: 'Defit/Demoliton',
          value: ''
        });
        services.push({
          key: 'Ceiling',
          value: ''
        });
        services.push({
          key: 'Shopfront Door',
          value: ''
        });
        services.push({
          key: 'AV system',
          value: ''
        });
        services.push({
          key: 'Safe',
          value: ''
        });
        services.push({
          key: 'Other General Maintenance',
          value: 'Any general maintenance issues. A complete description can be provided in the final step of this form.'
        });
        services.push({
          key: 'Other Services Maintenance',
          value: 'Any general services maintenance issues, mechanical, fire etc - A complete description can be provided in the final step of this form.'
        });
        break;
    }

    this.serviceDescriptions = services;
    services.forEach(x =>
      this.unfilteredServices.push({ key: x.key, value: x.key })
    );
    this.filteredServices = this.unfilteredServices;

  }

  applyDDLSelectionChange(key: string, value: string, currentDDLValueMap: Map<string, string>) {

    // use key to determine which DDL was changed
    switch (key) {

      case FormFieldTypes.Country:

        // filter region and job site lists
        const jobSitesFilteredByCountry = this.jobSiteList.filter(x => x.country === value);

        // filter region DDL
        if (this.activeDDLOptions[FormFieldTypes.Region]) {
          const filtered = [...new Set(jobSitesFilteredByCountry.map(item => item.state))].sort((a, b) => a.localeCompare(b));
          this.filteredRegions = this.arrayToOptions(filtered);
        }

        // filter job site DDL
        if (this.activeDDLOptions[FormFieldTypes.JobSite]) {
          const filtered = [...new Set(jobSitesFilteredByCountry.map(item => item.name))].sort((a, b) => a.localeCompare(b));
          this.filteredJobSiteNames = this.arrayToOptions(filtered);
        }

        break;

      case FormFieldTypes.Brand:

        // filter region and job site lists
        const jobSitesFilteredByBrand = this.jobSiteList.filter(x => x.brand.toLowerCase() === value.toLowerCase());

        // filter region DDL
        if (this.activeDDLOptions[FormFieldTypes.Region]) {
          const filtered = [...new Set(jobSitesFilteredByBrand.map(item => item.state))].sort((a, b) => a.localeCompare(b));
          this.filteredRegions = this.arrayToOptions(filtered);
        }

        // filter job site DDL
        if (this.activeDDLOptions[FormFieldTypes.JobSite]) {
          const filtered = [...new Set(jobSitesFilteredByBrand.map(item => item.name))].sort((a, b) => a.localeCompare(b));
          this.filteredJobSiteNames = this.arrayToOptions(filtered);
        }

        break;

      case FormFieldTypes.ClubType:

        // filter region and job site lists
        const jobSitesFilteredByClubType = this.jobSiteList.filter(x => x.clubType.toLowerCase() === value.toLowerCase());

        // filter region DDL
        if (this.activeDDLOptions[FormFieldTypes.Region]) {
          const filtered = [...new Set(jobSitesFilteredByClubType.map(item => item.state))].sort((a, b) => a.localeCompare(b));
          this.filteredRegions = this.arrayToOptions(filtered);
        }
        break;

      case FormFieldTypes.Region:

        // filter job sites, filter brands
        let jobSitesFilteredByRegion = this.jobSiteList.filter(x => x.state === value);

        if (this.activeDDLOptions[FormFieldTypes.Brand]) {
          const filtered = [...new Set(jobSitesFilteredByRegion.map(item => item.brand))].sort((a, b) => a.localeCompare(b));
          this.filteredBrands = this.arrayToOptions(filtered);
        }

        if (this.activeDDLOptions[FormFieldTypes.ClubType]) {
          const filtered = [...new Set(jobSitesFilteredByRegion.map(item => item.clubType))].sort((a, b) => a.localeCompare(b));
          this.filteredClubTypes = this.arrayToOptions(filtered);
        }

        if (this.activeDDLOptions[FormFieldTypes.Country]) {
          const filtered = [...new Set(jobSitesFilteredByRegion.map(item => item.country))].sort((a, b) => a.localeCompare(b));
          this.filteredCountries = this.arrayToOptions(filtered);
        }

        break;

      case FormFieldTypes.BuildingName:

        let jobSitesFilteredByBuildingName = this.jobSiteList.filter(x => x.buildingName === value);

        if (this.activeDDLOptions[FormFieldTypes.LotNumber]) {
          const filtered = [...new Set(jobSitesFilteredByBuildingName.map(item => item.lotNumber.toString()))].sort((a, b) => a.localeCompare(b));
          this.filteredLotNumbers = this.arrayToOptions(filtered);
        }
        break;
      case FormFieldTypes.LotNumber:
        // no filtering - select job site?
        break;

      case FormFieldTypes.ServiceType:
        // no filtering required
        break;

      case FormFieldTypes.JobSite:
        // select region, country, brand. no filtering required
        break;
    }

    this.filterJobSiteNames(currentDDLValueMap);

  }

  private filterJobSiteNames(currentDDLValueMap: Map<string, string>) {

    let filteredJobSites = this.jobSiteList;

    // if the user has previously chosen a region
    if (currentDDLValueMap.has(FormFieldTypes.Region)) {
      // if the previously selected region has been filtered out due to other selections
      if (this.filteredRegions.filter(x => x.value == currentDDLValueMap.get(FormFieldTypes.Region)).length == 0) {
        // bin that filtering criteria
        currentDDLValueMap.delete(FormFieldTypes.Region)
      }
    }

    // if the user has previously chosen a club type 
    if (currentDDLValueMap.has(FormFieldTypes.ClubType)) {
      // filter job sites to chosen club type
      filteredJobSites = filteredJobSites.filter(x => x.clubType == currentDDLValueMap.get(FormFieldTypes.ClubType));
    }

    // if the user has previously chosen a region
    if (currentDDLValueMap.has(FormFieldTypes.Region)) {
      // filter job sites to chosen region
      filteredJobSites = filteredJobSites.filter(x => x.state === currentDDLValueMap.get(FormFieldTypes.Region));
    }

    const filteredJobSiteNames = [...new Set(filteredJobSites.map(item => item.name))].sort((a, b) => a.localeCompare(b));
    this.filteredJobSiteNames = this.arrayToOptions(filteredJobSiteNames);
  }

  public filteredListContainsOneItem(key: string) {
    var list = [];

    switch (key) {
      case FormFieldTypes.Brand:
        list = this.filteredBrands;
        break;
      case FormFieldTypes.Country:
        list = this.filteredCountries;
        break;
      case FormFieldTypes.Region:
        list = this.filteredRegions;
        break;
      case FormFieldTypes.ServiceType:
        list = this.filteredServices;
        break;
      case FormFieldTypes.JobSite:
        list = this.filteredJobSiteNames;
        break;
      case FormFieldTypes.ClubType:
        list = this.filteredClubTypes;
        break;
      default:
        break;
    }

    return list.length == 1;
  }

  public arrayToOptions(arrayOptions: string[]) {

    var options: DropdownItem[] = [];

    arrayOptions.forEach(x => {
      options.push({ key: x, value: x });
    })

    return options;
  }
}

export interface DropdownItem {
  key: string;
  value: string;
}
