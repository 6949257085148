import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { JobImpersonatorService } from '../job-impersonator.service';
import { PriorityTypes } from '../globals.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  clientID: number = environment.clientID;
  showLogo: boolean = environment.logoConfig.showLogoInToolbar;
  logoIsSquare: boolean = environment.logoConfig.logoIsSquare;
  showInsertDummyJobButton = environment.showInsertDummyJobButton;

  isPasswordProtected: boolean = environment.loginConfig.isPasswordProtected;

  isInsertingDummyJob: boolean = false;
  
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private jobImpersonator: JobImpersonatorService,
    
    ) { }

  ngOnInit(): void {}

  // don't show logout button if no user logged in
  showLogoutButton(): boolean {

    if (!this.isPasswordProtected) {
      return false;
    }

    return this.authService.checkLoginRelaxed();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  async insertDummyJob(priorityType: number) {
    this.isInsertingDummyJob = true;
    await this.jobImpersonator.TriggerTestJob(priorityType);
    // redirect to summary page
    this.isInsertingDummyJob = false;
    this.router.navigate(['/complete']);
  }

}
