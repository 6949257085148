<div class="home-container">
    <div class="splash-content">

        <div class="logo-container">
            <img src="../../assets/img/5319/logo.png">
        </div>
    
        <div class="home-title">
            STORE MAINTENANCE PORTAL
        </div>

        <div class="hero-image">
            <img src="../../assets/img/5319/hero-image-text.png">
        </div>
    
    </div>
</div>