import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/utility.service';

import { QuestionBase } from '../question-base';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent {

  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;

  @Output() ddlSelectionChangeEvent = new EventEmitter<string>();

  showDescription: boolean = false;
  activeDescription: string = '';
  useConvertToTitleCase: boolean = environment.useConvertToTitleCase;

  constructor(protected utilityService: UtilityService) { }

  get isValid() { return this.form.controls[this.question.key].valid; }

  applySelectionChange(newSelection: string) {
    this.updateDescription(newSelection);
    this.ddlSelectionChangeEvent.emit(newSelection);
  }

  updateDescription(newSelection: string) {
    // the description is text which appears under the DDL when a selection is made
    // as of 2022-12-23, this is only set up for serviceType
    const selectedOption = this.question.optionDescriptions.filter(x => x.key === newSelection)[0];
    if (selectedOption) {
      this.activeDescription = selectedOption.value;
      this.showDescription = true;
    }
  }
}
