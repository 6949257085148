export class QuestionBase<T> {
    value: T | undefined;
    key: string;
    label: string;
    required: boolean;
    isDisabled: boolean;
    order: number;
    controlType: string;
    type: string;
    options: { key: string, value: string }[];
    filteredOptions: { key: string, value: string }[];
    optionDescriptions: { key: string, value: string }[];
    testID: string;

    validationType: string;     // so we can define custom validators in question-control service
    mustMatchField: string;     // for making sure two fields have the same input, eg email and confirmEmail


    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        isDisabled?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        options?: { key: string, value: string }[];
        optionDescriptions?: { key: string, value: string }[];
        testID?: string;
        validationType?: string;
        mustMatchField?: string;
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.isDisabled = options.isDisabled || false;
        this.options = options.options || [];
        this.filteredOptions = options.options || [];
        this.optionDescriptions = options.optionDescriptions || [];
        this.testID = options.testID || '';

        this.validationType = options.validationType || '';
        this.mustMatchField = options.mustMatchField || '';
    }
}