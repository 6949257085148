<div class="triage-prompt">
    Please indicate the priority of the works required. Click the info button for more detail on which jobs correspond to which priority types.
</div>

<div class="priority-picker-container">

    <div class="priority-option-container" id="emergency-container"  [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.Emergency}">
        
        <div class="priority-option-title">Emergency</div>
        <div class="priority-option-subtitle">Issues that immediately and effectively threaten safety and store operation.</div>
        <div class="priority-option-button-container">
            <button mat-button class="select-button" id="emergency-button" data-testid="bookingdetails-emergencyPriorityButton" (click)="prioritySelected(priorityTypes.Emergency)" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.Emergency}">Select</button>
            <button mat-button class="info-button" (click)="openInfoMenu(priorityTypes.Emergency)" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.Emergency}"><mat-icon>info</mat-icon></button>
        </div>

    </div>
    <div class="priority-option-container" id="medium-container" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.MediumPriority}">
        <div class="priority-option-title">Medium Priority</div>
        <div class="priority-option-subtitle">Issues that do not pose immediate danger to people or property.</div>
        <div class="priority-option-button-container">
            <button mat-button class="select-button" id="medium-button" data-testid="bookingdetails-mediumPriorityButton" (click)="prioritySelected(priorityTypes.MediumPriority)" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.MediumPriority}">Select</button>
            <button mat-button class="info-button" (click)="openInfoMenu(priorityTypes.MediumPriority)" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.MediumPriority}"><mat-icon>info</mat-icon></button>
        </div>
    </div>
    <div class="priority-option-container" id="low-container" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.LowPriority}">
        <div class="priority-option-title">Low Priority</div>
        <div class="priority-option-subtitle">Issues that don’t pose a safety risk, don’t damage the property, and don’t prevent the store from trading.</div>
        <div class="priority-option-button-container">
            <button mat-button class="select-button no-hover-effect" id="low-button" data-testid="bookingdetails-lowPriorityButton" (click)="prioritySelected(priorityTypes.LowPriority)" [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.LowPriority}">Select</button>
            <button mat-button class="info-button" (click)="openInfoMenu(priorityTypes.LowPriority)"  [ngClass]="{'priority-option-container-unselected' : prioritySelection != priorityTypes.NoPriority && prioritySelection != priorityTypes.LowPriority}"><mat-icon>info</mat-icon></button>
        </div>
    </div>
</div>