import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // attempt to authorise with the login credentials in local storage. if auth fails, redirect to login page.
      return this.authService.authStatus().pipe(
        map(success => {
          if (!success) {
            this.authService.logout();
            this.router.navigate(['/login']);
            return false
          } else {
            return true;
          }
        })
      )
    }
}
