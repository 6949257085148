import { Injectable } from '@angular/core';

import { QuestionBase } from './forms/question-base';
import { TextboxQuestion } from './forms/question-textbox';
import { DropdownQuestion } from './forms/question-dropdown';
import { TextareaQuestion } from './forms/question-textarea';

import { of, Observable } from 'rxjs';
import { FormFieldTypes, CustomValidationTypes, Clients } from './globals.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  public emailQuestion!: TextboxQuestion;
  public confirmEmailQuestion!: TextboxQuestion;
  public secondEmailQuestion!: TextboxQuestion;

  constructor(private clients: Clients) { }

  getJobSiteAndServiceTypeQuestionsByClient(clientID: number) {
    switch (clientID) {
      case this.clients.TheBodyShop:
        return this.getJobSiteAndServiceTypeQuestionsTBS();
      case this.clients.TheBodyShopNZ:
        return this.getJobSiteAndServiceTypeQuestionsTBSNZ();
      case this.clients.GlobalRetailBrands:
        return this.getJobSiteAndServiceTypeQuestionsGRB();
      case this.clients.DerrimutGyms:
        return this.getJobSiteAndServiceTypeQuestionsDerrimut();
      case this.clients.AHA:
        return this.getJobSiteAndServiceTypeQuestionsAHA();
      case this.clients.OneTouchDirect:
        return this.getJobSiteAndServiceTypeQuestionsOneTouchDirect();
      case this.clients.LIDS:
        return this.getJobSiteAndServiceTypeQuestionsLids();
      case this.clients.VIVA:
        return this.getJobSiteAndServiceTypeQuestionsVIVA();
      case this.clients.RRRDW:
        return this.getJobSiteAndServiceTypeQuestionsRRRDW();
      case this.clients.EasyLink:
        // return this.getJobSiteAndServiceTypeQuestionsEasyLinkDirectWorks();
        return this.getJobSiteAndServiceTypeQuestions_EasyLink_JobSiteList();
      default:
        // todo: set up a default config that isn't based on TBS
        return this.getJobSiteAndServiceTypeQuestionsTBS();
    }
  }

  //#region "Question Getters"
  getServiceTypeDropdownQuestion(): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.ServiceType,
      label: 'Service Type',
      required: true,
      order: 1,
      options: [],
      testID: 'bookingdetails-serviceType',
    });
  }
  getBuildingNameQuestion(): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.BuildingName,
      label: 'Building',
      required: true,
      order: 2,
      options: [],
      testID: 'bookingdetails-buildingName',
      isDisabled: true,
    });
  }

  getLotNumberQuestion(): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.LotNumber,
      label: 'Lot Number',
      required: true,
      order: 2,
      options: [],
      testID: 'bookingdetails-lotNumber',
      isDisabled: true,
    });
  }

  getBusinessNameQuestion(): TextareaQuestion {
    return new TextareaQuestion({
      key: FormFieldTypes.BusinessName,
      label: 'Business Name',
      required: true,
      order: 2,
      testID: 'bookingdetails-businessName',
      validationType: CustomValidationTypes.businessNameMaxLength
    });
  }

  getClubTypeQuestion(): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.ClubType,
      label: 'Club Type',
      required: true,
      order: 1,
      testID: 'bookingdetails-clubType',
      options: [],
      isDisabled: true,
    });
  }

  getRegionQuestion(): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.Region,
      label: 'Region',
      required: true,
      order: 2,
      testID: 'bookingdetails-region',
      options: [],
      isDisabled: true,
    });
  }


  getJobSiteQuestion(label: string): DropdownQuestion {
    return new DropdownQuestion({
      key: FormFieldTypes.JobSite,
      label: label,
      required: true,
      order: 3,
      testID: 'bookingdetails-jobSite',
      options: [],
      isDisabled: true,
    })
  }
  //#endregion


  getJobSiteAndServiceTypeQuestions_EasyLink_JobSiteList(): Observable<InputFieldGroup[]> {

    const strataRow: QuestionBase<string>[] = [
      this.getBuildingNameQuestion(),
      this.getLotNumberQuestion(),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion(),
    ];

    const jobSiteGroup: InputFieldGroup = { prompt: 'Please select your building and lot number:', questionRows: [strataRow] };
    const servicesGroup: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };

    return of([jobSiteGroup, servicesGroup]);
  }

  getJobSiteAndServiceTypeQuestions_EasyLink_DirectWorks(): Observable<InputFieldGroup[]> {
    const servicesRow: QuestionBase<string>[] = [this.getServiceTypeDropdownQuestion()];
    const group: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group]);
  }

  getJobSiteAndServiceTypeQuestionsAHA(): Observable<InputFieldGroup[]> {
    const servicesRow: QuestionBase<string>[] = [this.getServiceTypeDropdownQuestion()];
    const group: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group]);
  }

  getJobSiteAndServiceTypeQuestionsOneTouchDirect(): Observable<InputFieldGroup[]> {
    const servicesRow: QuestionBase<string>[] = [this.getServiceTypeDropdownQuestion()];
    const group: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group]);
  }



  getJobSiteAndServiceTypeQuestionsRRRDW(): Observable<InputFieldGroup[]> {

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const businessDetailsRow: QuestionBase<string>[] = [
      this.getBusinessNameQuestion(),
    ];

    const group1: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    const group2: InputFieldGroup = { prompt: 'Please enter the name of your business:', questionRows: [businessDetailsRow] };
    return of([group1, group2]);
  }



  getJobSiteAndServiceTypeQuestionsVIVA(): Observable<InputFieldGroup[]> {

    const clubTypeRow: QuestionBase<string>[] = [
      this.getClubTypeQuestion(),
    ];

    const locationRow: QuestionBase<string>[] = [
      this.getRegionQuestion(),
      this.getJobSiteQuestion('Store'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const group1: InputFieldGroup = { prompt: 'Please select the Club Type you require:', questionRows: [clubTypeRow] };
    const group2: InputFieldGroup = { prompt: 'Please select your region and store location:', questionRows: [locationRow] };
    const group3: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group1, group2, group3]);

  }

  getJobSiteAndServiceTypeQuestionsDerrimut(): Observable<InputFieldGroup[]> {

    const row2: QuestionBase<string>[] = [
      this.getJobSiteQuestion('Venue'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion(),
    ];

    const group2: InputFieldGroup = { prompt: 'Please select your gym location:', questionRows: [row2] };
    const group3: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group2, group3]);

  }

  getJobSiteAndServiceTypeQuestionsTBS(): Observable<InputFieldGroup[]> {

    const row1: QuestionBase<string>[] = [
      this.getRegionQuestion(),
      this.getJobSiteQuestion('Store'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const group1: InputFieldGroup = { prompt: 'Please select your region and store location:', questionRows: [row1] };
    const group2: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group1, group2]);
  }

  getJobSiteAndServiceTypeQuestionsTBSNZ(): Observable<InputFieldGroup[]> {

    const row1: QuestionBase<string>[] = [
      this.getRegionQuestion(),
      this.getJobSiteQuestion('Store'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const group1: InputFieldGroup = { prompt: 'Please select your region and store location:', questionRows: [row1] };
    const group2: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group1, group2]);

  }

  getJobSiteAndServiceTypeQuestionsLids(): Observable<InputFieldGroup[]> {

    const row2: QuestionBase<string>[] = [
      this.getRegionQuestion(),
      this.getJobSiteQuestion('Store'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const group1: InputFieldGroup = { prompt: 'Please select your region and store location:', questionRows: [row2] };
    const group2: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group1, group2]);

  }

  getBrandQuestion() {
    return new DropdownQuestion({
      key: FormFieldTypes.Brand,
      label: 'Brand',
      required: true,
      order: 1,
      options: []
    });
  }

  getJobSiteAndServiceTypeQuestionsGRB(): Observable<InputFieldGroup[]> {

    const row1: QuestionBase<string>[] = [
      this.getBrandQuestion(),
    ];

    const row2: QuestionBase<string>[] = [
      this.getRegionQuestion(),
      this.getJobSiteQuestion('Store'),
    ];

    const servicesRow: QuestionBase<string>[] = [
      this.getServiceTypeDropdownQuestion()
    ];

    const group1: InputFieldGroup = { prompt: 'Please select your brand:', questionRows: [row1] };
    const group2: InputFieldGroup = { prompt: 'Please select your region and store location:', questionRows: [row2] };
    const group3: InputFieldGroup = { prompt: 'Please select the service you require:', questionRows: [servicesRow] };
    return of([group1, group2, group3]);

  }

  getContactDetailQuestions(): Observable<InputFieldGroup[]> {

    const contactDetailsRow1: QuestionBase<string>[] = [

      new TextboxQuestion({
        key: 'firstName',
        label: 'First Name',
        required: true,
        order: 1,
        testID: 'bookingdetails-firstName',

      }),
      new TextboxQuestion({
        key: 'lastName',
        label: 'Last Name',
        required: true,
        order: 2,
        testID: 'bookingdetails-lastName',

      }),
      new TextboxQuestion({
        key: 'phoneNumber',
        label: 'Phone Number',
        required: true,
        order: 3,
        testID: 'bookingdetails-phoneNumber',

      })

    ];

    this.emailQuestion = new TextboxQuestion({
      key: 'email',
      label: 'Email',
      required: true,
      order: 4,
      testID: 'bookingdetails-email',
      validationType: CustomValidationTypes.Email,
      mustMatchField: 'confirmEmail', // make sure whatver is in this field matches whatever is in the confirmEmail field
    });

    this.confirmEmailQuestion = new TextboxQuestion({
      key: 'confirmEmail',
      label: 'Confirm Email',
      required: true,
      order: 5,
      testID: 'bookingdetails-confirmEmail',
      validationType: CustomValidationTypes.Email,
    });

    const contactDetailsRow2: QuestionBase<string>[] = [
      this.emailQuestion,
      this.confirmEmailQuestion,
    ];

    let questionRows = [contactDetailsRow1, contactDetailsRow2];

    if (environment.contactOptions.secondEmail) {
      this.secondEmailQuestion = new TextboxQuestion({
        key: 'secondEmail',
        label: 'Additional Email (Optional)',
        required: false,
        order: 6,
        testID: 'bookingdetails-secondEmail',
        validationType: CustomValidationTypes.Email,
      });

      const contactDetailsRow3: QuestionBase<string>[] = [
        this.secondEmailQuestion
      ];

      questionRows.push(contactDetailsRow3);
    }

    const descriptionRow: QuestionBase<string>[] = [
      new TextareaQuestion({
        key: FormFieldTypes.ProblemDescription,
        label: 'Problem Description',
        required: true,
        order: 6,
        testID: 'bookingdetails-problemDescription',
      })
    ];

    const group1: InputFieldGroup = { prompt: 'Please fill in your contact details:', questionRows: questionRows };
    const group2: InputFieldGroup = { prompt: 'Please provide a detailed description of what works are required. This will enable our team to promptly allocate a team to the works.', questionRows: [descriptionRow] }
    return of([group1, group2]);

  }
}

export interface InputFieldGroup {
  prompt: string;
  questionRows: QuestionBase<string>[][];
}
