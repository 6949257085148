import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DatetimePickerComponent implements OnInit {

  @Output() dateChangeEvent = new EventEmitter<string>();
  @Output() timeChangeEvent = new EventEmitter<string>();

  // selection data
  selectedDate: Date | null = null;
  selectedAppointmentTime: string = '';
  dateToday: Date = new Date();
  minDate: Date = new Date();

  // forms
  dateTimeForm = new FormGroup({
    appointmentDate: new FormControl(''),
    appointmentTime: new FormControl('', [Validators.required])
  });

  // available appointment windows
  bookingWindowsMorning: number[] = [7, 8, 9, 10, 11, 12];
  bookingWindowsAfternoon: number[] = [13, 14, 15, 16, 17, 18];
  bookingWindowsOvernight: number[] = [20, 22, 24, 2, 4, 6];

  constructor(protected utilityService: UtilityService) { }

  ngOnInit(): void {
    // earliest date that can be booked is tomorrow
    // todo: if tomorrow is a weekend day, make minDate the following monday
    this.minDate.setDate(this.dateToday.getDate() + 1);
    this.setSelectedDate(this.minDate);
  }

  dateIsWeekend = (d: any): boolean => {
    if (d) {
      const day = d.getDay();
      return day !== 0 && day !== 6 ;
    }
    return true;
  }

  setSelectedDate(value: any) {
    this.selectedDate = value;
    this.dateTimeForm.controls['appointmentDate'].setValue(value);
    this.dateChangeEvent.emit(value);
  }

  setSelectedTime(value: any) {
    this.timeChangeEvent.emit(value.value);
  }

}
