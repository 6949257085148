<div class="privacy-container">

    <div class="privacy-title">Privacy Policy</div>
    <hr />
    <div class="privacy-heading">1. SCOPE</div>
    <div class="privacy-block">1.1 The Policy applies to all employees of Global Home Response Pty Ltd (GHR). The
            Policy sets out how we treat the Privacy of parties with whom we interact. The Policy covers Global Home
            Response Pty Ltd and all subsidiaries, associates and related bodies corporate from time-to-time as defined
            by the Corporations Act 2001.
    </div>
    
    <hr />
    <div class="privacy-heading">2. DEFINITIONS</div>
    <div class="privacy-block">
        <div>2.1 <strong>APPs</strong> means the Australian Privacy Principles as amended from time to time.</div>
        <div>2.2 <strong>GHR</strong> – Global Home Response Pty Ltd and its subsidiaries and related entities.</div>
        <div>2.3 <strong>Policy</strong> – This privacy policy and its related contents.</div>
        <div>2.4 <strong>Privacy</strong> – Information and/or opinion including name, address, telephone, e-mail
            address, date of
            birth, gender status, martial state, driver’s information, financial history and property information about
            an identifiable individual.</div>
        <div>2.5 <strong>Privacy Act</strong> – Privacy Act 1998 as amended from time to time.</div>
        <div>2.6 <strong>Privacy Officer</strong> - The Officer charged with developing and implementing policies
            designed to protect
            employee and customer data from unauthorised access.</div>
    </div>
    <hr />
    <div class="privacy-heading">3. PURPOSE</div>
    <div class="privacy-block">

        <div>3.1 The Policy explains our personal information handling practices. It describes how we collect, use, and
            disclose information about you or your use of our services. The Policy outlines:</div>
        <div>(i) How we use this information;</div>
        <div>(ii) With whom we may share this information;</div>
        <div>(iii) What types of security procedures are in place to protect against the loss, misuse or alteration of
            information under our control; and</div>
        <div>(iv) How any inaccuracies in the information we hold are corrected and amended.</div>
    </div>
    <hr />
    <div class="privacy-heading">4. PROCEDURE</div>
    <div class="privacy-block">

        <div>4.1 We may use personal information for the purpose of marketing, but only where such information complies
            with the provisions of the Privacy Act and the APPs. Information collected from you may include:</div>
        <div>(i) For the purpose identified in Section 4.2;</div>
        <div>(ii) To identify you and to conduct appropriate verification checks and other enquires;</div>
        <div>(iii) To help determine the best and most appropriate goods and services for you;</div>
        <div>(iv) To administer our products and services, including when dealing with insurers;</div>
        <div>(v) To otherwise respond to enquires received by you;</div>
        <div>(vi) To process, confirm, fulfil and update you about your enquires and to carry out our obligations
            arising from any contracts entered between you and us;</div>
        <div>(vii) To perform authorised transactions with you;</div>
        <div>(viii) To discuss claims handling and history with insurers;</div>
        <div>(ix) To communicate with you and provide you with information (whether by e-mail, post or other means),
            about our products or services, where you have requested or consented to receiving this from us, or where
            this provision is otherwise permitted under the Privacy Act and the APPs;</div>
        <div>(x) To notify you about changes to goods and services;</div>
        <div>(xi) To receive and address feedback or complaints from you;</div>
        <div>(xii) When evaluating job applicants and personnel, including their contact details, employment history
            and educational qualifications; and</div>
        <div>(xiii) To protect our legal interests and fulfil our regulatory obligations (if and to the extent
            necessary).</div>
        <div>4.2 All our customers and others with whom we interact have the option to opt out of receiving marketing
            communications from us. If you do not wish to receive electronic marketing communications from us and/or
            selected third parties, you may opt out by clicking on the ‘unsubscribe’ link in any e-mail communications
            that we send you.</div>
        <div>4.3 Collection and storage of personal information and data - We may collect personal
            information such as that defined in 2.3 about individuals. Information shall only be collected directly
            from the individual concerned unless it is impracticable or unreasonable to do so or in circumstances where
            you would reasonably expect us to do otherwise, including as outlined further in this document.</div>
        <div>4.4 Personal information may be collected in the following circumstances, including:</div>
        <div>(i) During communications between individuals and our staff (including in person and over the telephone),
            through text messages you send us, through written forms through which you provide information and via the
            internet;</div>
        <div>(ii) When you telephone us or visit our website and make an enquiry to which a later response is requested
            and to do so requires your contact details;</div>
        <div>(iii) In communications with landlords, tenants, leasing agents and council officials;</div>
        <div>(iv) When you enter into a contract with us for goods or services;</div>
        <div>(v) When you pay for goods or services with methods other than cash;</div>
        <div>(vi) When you conduct credit checks though credit reporting bodies or various public domain databases;</div>
        <div>(vii) When we make enquires through ASIC, Centrelink or the Titles Office;</div>
        <div>(viii) When liaising with insurers, for the purposes of considering, processing and administering claims;</div>
        <div>(ix) When you ask to be included on a marketing distribution list; and</div>
        <div>(x) When evaluating job applicants and personnel, including their contact details, employment history and
            educational qualifications. This may include ‘sensitive information’ as defined in the Privacy Act. Further
            details about this Policy and practices for job applicants will be available at the time of application.</div>
        <div>4.5 When we use or collect information in relation to your use of digital services - We
            may use ‘cookies’ to collect data (typically not personal information) relating to your general internet
            usage. This data may include IP addresses, browser versions, number of visits and similar data relating to
            your navigation of the internet and our site. Cookies help us to improve our site and deliver a better and
            more tailored service, for instance by storing information about your preferences and allowing us to
            recognise you when you return to our site. You may adjust your internet browser to disable cookies. If
            cookies are disabled you may still use the website, but the website may be limited in the use of some of
            the features.</div>
    </div>
    <hr />

    <div class="privacy-heading">5. DISCLOSURE</div>
    <div class="privacy-block">


        <div>5.1 Information shall be disclosed for the purpose for which it has been collected and may only be
            disclosed to GHR companies. It may only be used for the purpose intended under the Policy. This may include
            insurers and other agreed third parties that assist in the continuity of services. Third parties may
            include:</div>
        <div>(i) Organisations who carry out credit, fraud and other security checks;</div>
        <div>(ii) Subcontractors carrying out works for and on behalf of GHR; and</div>
        <div>(iii) Third party software providers who store details of customer accounts for us or who provide other IT
            services.</div>
        <div>5.2 We may also disclose your personal information to third parties outside of the GHR companies in the
            following instances:</div>
        <div>(i) Where we have express permission to do so;</div>
        <div>(ii) Where it can reasonably be inferred from the circumstances that you consent to the disclosure to
            third parties;</div>
        <div>(iii) If a GHR company or substantially all its respective assets are acquired by a third party, in which
            case personal information which the company may hold about customers may be one of the transferred assets
            (subject to the same constraints on use and disclosure as under the Policy); and</div>
        <div>(iv) If the company is bound to disclose your personal information to comply with any legal obligation, or
            in order to apply our terms and conditions, or to protect the rights property or safety of our staff or
            customers.</div>
        <div>5.3 Security of personal information - The company will take reasonable steps to protect
            your personal information, including internal and external security, restricting access to personal
            information to those who may need to know, maintain technological products to prevent unauthorised computer
            access and regularly reviewing our technology to maintain security.</div>
        <div>5.4 What do we do if there is a data breach? In the event of a data breach, such as the
            unauthorised loss, use or disclosure of personal information, we will assess and respond in line with our
            applicable policies and procedures, which incorporate the requirements contained in the Privacy Act and the
            APPs. Pursuant to our obligations under the Privacy Act and the APPs, we will notify you where your
            personal information is involved in an eligible data breach that is likely to result in serious harm. Such
            notification will also include making recommendations about the steps you should take in response to the
            breach. Where required by law, the Australian Privacy and Information Commissioner will also be notified of
            an eligible data breach.</div>
        <div>5.5 Questions and concerns, access to/correction or updating of your personal information
            - You have the right to access the personal information the company may possess about you (with some
            exception) to find out the information held and/or correct it. To obtain a copy of the personal information
            we hold about you, please write to us at:</div>
        <div>The Privacy Officer</div>
        <div>Matt Coughlan</div>
        <div>Johns Lyng Group</div>
        <div>1 Williamsons Road</div>
        <div>DONCASTER VICTORIA 3108</div>
        <div>mcoughlan@johnslyng.com.au</div>
        <div>Please provide sufficient details about the information in question to help us locate it. We will then use
            commercially reasonable efforts to promptly determine if there is a problem and take the necessary
            corrective action within a reasonable time.</div>
        <div>If you are not satisfied with the outcome, then you may contact the Office of the Australian Privacy
            Commissioner:</div>
        <div>Office of the Australian Information Commissioner</div>
        <div>Website: www.oaic.gov.au</div>
        <div>Phone: 1300 363 992</div>
        <div>5.6 We may modify, alter or otherwise update the Policy at any time. We will post revisions on our website
            and encourage you to review the Policy from time to time. </div>
    </div>

    <hr/>
</div>