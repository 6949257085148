<div [formGroup]="form">
    <div [ngSwitch]="question.controlType">

        <div *ngSwitchCase="'textbox'" class="form-item">
            <mat-form-field appearance="fill">
                <mat-label [attr.for]="question.key">{{question.label}}</mat-label>
                <input matInput [attr.required]="question.required" [formControlName]="question.key" [id]="question.key"
                    [type]="question.type" [attr.data-testid]="question.testID">
            </mat-form-field>
        </div>

        <div *ngSwitchCase="'dropdown'" class="form-item">
            <mat-form-field appearance="fill">
                <mat-label [attr.for]="question.key">{{question.label}}</mat-label>
                <mat-select [id]="question.key" [formControlName]="question.key" attr.data-testid="{{question.testID}}"
                    (selectionChange)="applySelectionChange($event.value)" [disabled]="true">

                    <mat-option *ngFor="let opt of question.filteredOptions; let i=index" [value]="opt.key"
                        attr.data-testID="{{question.testID}}-{{i}}">

                        <div *ngIf="useConvertToTitleCase">
                            {{utilityService.convertToTitleCase(opt.value)}}
                        </div>
                        <div *ngIf="!useConvertToTitleCase">
                            {{opt.value}}
                        </div>

                    </mat-option>

                </mat-select>
            </mat-form-field>
            <div *ngIf="showDescription" class="ddl-description">
                {{ activeDescription }}
            </div>
        </div>

        <div *ngSwitchCase="'textarea'" class="form-item">
            <mat-form-field appearance="fill">
                <mat-label>{{question.label}}</mat-label>
                <textarea [formControlName]="question.key" [id]="question.key" [type]="question.type"
                    [attr.data-testid]="question.testID" matInput></textarea>
            </mat-form-field>
        </div>

    </div>
</div>