<form [formGroup]="dateTimeForm">
    <div class="triage-prompt">
        Please indicate when the works need to be completed by. Note, this is a guide only and we
        will
        be in contact with you to confirm.
    </div>
    
    <div class="date-time-container">
    
        <div class="calendar-container">
            <mat-calendar [(selected)]="selectedDate" [minDate]="minDate"
                [dateFilter]="dateIsWeekend" (selectedChange)="setSelectedDate($event)">
            </mat-calendar>
        </div>
    
    
        <div class="time-window-container">
            <mat-radio-group formControlName="appointmentTime"
                [(ngModel)]="selectedAppointmentTime" (change)="setSelectedTime($event)">
    
                <div class="time-window-column">
                    <div class="time-window-title">
                        Morning
                    </div>
    
                    <div class="appointment-time-group">
                        <div class="radio-button-container"
                            *ngFor="let time of bookingWindowsMorning"
                            [ngClass]="{'radio-button-container-selected': time.toString() == selectedAppointmentTime}">
                            <mat-radio-button value="{{time}}">{{utilityService.getAMPMString(time)}}
                            </mat-radio-button>
                        </div>
                    </div>
    
                </div>
    
                <div class="time-window-column">
                    <div class="time-window-title">
                        Afternoon
                    </div>
                    <div class="appointment-time-group">
    
                        <div class="radio-button-container"
                            *ngFor="let time of bookingWindowsAfternoon"
                            [ngClass]="{'radio-button-container-selected': time.toString() == selectedAppointmentTime}">
                            <mat-radio-button value={{time}}>{{utilityService.getAMPMString(time)}}
                            </mat-radio-button>
                        </div>
                    </div>
                </div>
    
                <div class="time-window-column">
                    <div class="time-window-title">
                        Overnight
                    </div>
                    <div class="appointment-time-group">
                        <div class="radio-button-container"
                            *ngFor="let time of bookingWindowsOvernight" id="{{time}}"
                            [ngClass]="{'radio-button-container-selected': time.toString() == selectedAppointmentTime}">
                            <mat-radio-button value={{time}}>{{utilityService.getAMPMString(time)}}
                            </mat-radio-button>
                        </div>
                    </div>
                </div>
    
            </mat-radio-group>
        </div>
    </div>
</form>