import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { JobDataService } from '../job-data.service';
import { environment } from 'src/environments/environment';

import { GoogleMapsModule } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

@Component({
  selector: 'app-autocomplete-address-form',
  templateUrl: './autocomplete-address-form.component.html',
  styleUrls: ['./autocomplete-address-form.component.css']
})
export class AutocompleteAddressFormComponent implements OnInit {

  @Input() addressForm!: FormGroup;

  @ViewChild('addressSearch')
  public searchElementRef!: ElementRef;

  public searchQuery: string = '';

  constructor(
    private fb: FormBuilder, 
    private ngZone: NgZone,
    private jobDataService: JobDataService,
    private toastr: ToastrService,
    private route: ActivatedRoute
    ) { }
  
  addressRef!: ElementRef;

  ngOnInit(): void {
    this.route.queryParams // get querystring params
      .subscribe(params => {
        const unit = params['unit'] || '';
        const address = params['address'] || '';
        const suburb = params['suburb'] || '';
        const postcode = params['postcode'] || '';
        const state = params['state'] || '';
        
        this.addressForm.controls['streetAddress'].setValue(address);
        this.addressForm.controls['suburb'].setValue(suburb);
        this.addressForm.controls['state'].setValue(state);
        this.addressForm.controls['postcode'].setValue(postcode);
        this.addressForm.controls['unit'].setValue(unit);
    });
  }

  ngAfterViewInit(): void {

    // todo: factor out lat/lng into environment file, or a service if things get too complex 
    // Victoria, Australia approximate center point
    const center = { lat: -36.602, lng: 145.469 };

    // Create a bounding box with sides ~4.5 degrees away from the center point (roughly encompasses VIC)
    const victoriaBounds = {
      north: center.lat + 4.5,
      south: center.lat - 4.5,
      east: center.lng + 4.5,
      west: center.lng - 4.5,
    };

    var autoCompleteOptions = {};

    if(environment.mapsConfig.isBoundToRegion) {
       autoCompleteOptions = {
        bounds: victoriaBounds,                                                           // defines a rough box around the VIC region
        componentRestrictions: { country: environment.mapsConfig.googleMapsCountries },   // only show relevant countries (just au for intial impl)
        fields: ["geometry", "place_id"],                                                 // limit the fields we return so API requests are cheaper
        strictBounds: false,                                                              // do not exclude areas that are outside the specified bounds
        types: ["address"],                                                               // search for street addresses, not other location types
      }
    } else {
      // site to be open nationwide, load all the suburbs accordingly and remove the VIC bias on the google address API.
      autoCompleteOptions = {                                                       // defines a rough box around the VIC region
        componentRestrictions: { country: environment.mapsConfig.googleMapsCountries },   // only show relevant countries (just au for intial impl)
        fields: ["geometry", "place_id"],                                                 // limit the fields we return so API requests are cheaper
        strictBounds: false,                                                              // do not exclude areas that are outside the specified bounds
        types: ["address"],                                                               // search for street addresses, not other location types
      }
    }
        // attach autocomplete to input field
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement as HTMLInputElement, autoCompleteOptions
        );
    
        // when the user selects an autocomplete result
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
    
            // fetch the place data
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    
            //verify result exists
            if (place.geometry === undefined || place.geometry === null) {
              console.log('Places API error: Geometry undefined.')
              this.toastr.error('Could not locate address.', 'Error');
              return;
            }
            if (place.place_id === undefined || place.place_id === null) {
              console.log('Places API error: PlaceID undefined.')
              this.toastr.error('Could not locate address.', 'Error');
              return;
            }
    
            // log results when testing
            // console.log({ place }, place.geometry.location?.lat());
    
            // instantiate the fields we want to store from the API call
            let streetNumber = '';
            let streetName = '';
            let postcode = '';
            let suburbName = '';
            let country = '';
            let unit = '';
            let administrative_area_level_1 = ''; // aka: STATE
            let locality = '';
    
            // sometimes street or unit numbers don't get fetched. set up flags so we can notify the user
            var streetNumberFound = false;
            var unitNumberFound = false;
    
            // geocoder is more likely to fetch the full address than autocomplete, so give it the PlaceID and try
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({placeId: place.place_id})
            .then(({results}) => {
    
              if (results[0]) {
    
                const result = results[0];
    
                // attempt to get country from response. this helps us handle NZ addresses in the next loop.
                result.address_components.forEach((s: AddressComponent) => {
                  s.types.forEach((t: string) => {
                    if (t == "country") {
                      country = s.short_name;
                    }
                  });
                });
    
                // attempt to parse the remaining address data
                result.address_components.forEach((s: AddressComponent) => {
                  s.types.forEach((t: string) => {
                    if (t == 'street_number') {
                      streetNumber = s.long_name;
                      streetNumberFound = true;
                    } else if (t == 'route') {
                      streetName = s.long_name;
                    } else if (t == 'postal_code') {
                      postcode = s.long_name;
                    } else if (t == 'locality') {
                      locality = s.long_name;
                      
                      if (country.toUpperCase() == 'AU') {
                        suburbName = s.long_name;
                      }
                    } else if (t == 'sublocality') {
                      if (country.toUpperCase() == 'NZ') {
                        suburbName = s.long_name;
                      }
                    } else if (t == "subpremise") {
                      unit = s.long_name;
                      unitNumberFound = true;
                    }
                    else if (t == 'administrative_area_level_1') {
                      administrative_area_level_1 = s.short_name;
                    }
                  });
                });
    
                if (!streetNumberFound) {
                  // the user definitely needs to enter some details manually - show an error.
                  this.toastr.error('Could not prefill street number. Please enter any missing details manually.', 'Error');
                }
    
                if (!unitNumberFound) {
    
                  // if we didn't get a unit number back, have a look at the search string
                    if (this.searchQuery) {    
                      // if it looks like the search string contained a unit number, show an error so the user knows to check the autofilled fields
                      if (this.addressLooksLikeItContainsAUnit(this.searchQuery)) {
                        this.toastr.warning('Could not prefill unit number. Please enter any missing details manually.', 'Warning');
                      }
                    }
                  }
    
                // populate address fields with whatever data we got back
                this.addressForm.controls['streetAddress'].setValue(streetNumber + ' ' + streetName);
                this.addressForm.controls['suburb'].setValue(suburbName);
                this.addressForm.controls['state'].setValue(administrative_area_level_1);
                this.addressForm.controls['postcode'].setValue(postcode);
                this.addressForm.controls['unit'].setValue(unit);
    
              } else {
                this.toastr.error('Could not prefill address. Please enter any missing details manually.', 'Error');
              }
            });
    
          });
    
        });
  }

  addressLooksLikeItContainsAUnit(address: string): boolean {

    var output = false;

    const subpremiseIndicators = ["/", "-", "unit", "apt"];     // eg: 2/14, 2-14, unit 2 14, apt 2 14

    const unitRegex = /(?<![a-zA-Z])([uU])(?:\s?)(\d+)/g;       // eg: U2 14, U2/14, U 2/14, ...
    const subpremiseRegex = /(\d+)(\s?)([a-zA-Z]{1})(\s|$)/gs;  // eg: 2A, 2 A, 26 C, ...
    
    if (subpremiseIndicators.some(x => address.toLowerCase().includes(x.toLowerCase()))) {
      // basic check, but decent chance the address specifies a unit
      output = true;
    }

    if (!output) {
      // more advanced checks, though probably not comprehensive
      if (address.match(unitRegex) || address.match(subpremiseRegex)) {
        output = true;
      }
    }
    
    return output;
  }

}
