<div class="terms-container">
    <div class="terms-title">Terms and Conditions</div>

    <hr/>
    
    <div class="terms-heading">Background</div>
    <div class="terms-block">
        
        <div>These are our terms and conditions. They set out the conditions for use of our Platforms, including our
        obligations to you, your rights, and your obligations to us.</div>
        <div>We reserve the right to revise and update these Terms and Conditions at any time. Please periodically
        review these Terms and Conditions. </div>
        <div>By using our Platform, you acknowledge and agree to abide by these terms.</div>

        <div>
            Global Home Response
            (A.B.N. 96 350 743 600) (GHR)
            operates
            an online platform,
            as well as mobile
            applications, through which customers ("Users") can book a Job request for the provision of Trade Services,
            by submitting a Booking Request. GHR then matches the most suitable Tradie from its database of Trades to
            the Booking Request.
        </div>

        <div>This is a contract between GHR and the Users.</div>
        <div>
            GHR enters into agreements with Users for provision of Trade Services to Users. The User is deemed to have entered into this Contract and a contractual relationship with GHR through the making and acceptance of a Booking.
            And the parties hereby agree: access to and use of the Website mobile phone applications is subject to the terms and conditions outlined in this agreement (the "Terms").
        </div>
    </div>
    <hr/>
    <div class="terms-heading">1. Definitions and Interpretation</div>
    <div class="terms-block">

        <div>1.1. In these Terms (including the recitals), unless the context otherwise requires:</div>
        <div>"Booking" means the booking made by you on the Website or mobile phone applications for the provision of
        Trade Services;</div>
        <div>"Booking System" means the online system which enables you to make a Booking;</div>
        <div>"Fee" means the fee, as advised by GHR from time to time, (inclusive of goods and service tax, if
        applicable) for the Trade Services charged and invoiced by GHR;</div>
        <div>"Intellectual Property Rights" means all present and future rights anywhere in the world in relation to
        copyright, trademarks, designs, patents or other proprietary rights, or any rights to registration of such
        rights whether existing before or after your access to the Website and/or mobile phone applications;</div>
        <div>"Site Content" means all material, content and information made available on the Website and/or mobile
        phone applications including but not limited to written text, graphics, images, photographs, logos,
        trademarks, audio material, video material and any other forms of expression;</div>
        <div>“Tradie” means the individual assigned by GHR to deliver the Trade Services that you have booked;</div>
        <div>"you", "your" means you as the user of the Website or mobile phone application.</div>
        <div>1.2. In these Terms, unless the context otherwise requires:</div>
        <div>(a) headings are for convenience only and do not affect its interpretation or construction;</div>
        <div>(b) the singular includes the plural and vice versa;</div>
        <div>(c) references to recitals, clauses, subclauses, paragraphs, annexures or schedules are references to
        recitals, clauses, subclauses, paragraphs, annexures and schedules of or to these Terms;</div>
        <div>(d) words importing a gender include other genders;</div>
        <div>(e) the word “person” means a natural person and any association, body or entity whether incorporated or
        not;</div>
        <div>(f) where any word or phrase is defined, any other part of speech or other grammatical form of that word
        or phrase has a cognate meaning;</div>
        <div>(g) a reference to any statute, proclamation, rule, code, regulation or ordinance includes any amendment,
        consolidation, modification, re-enactment or reprint of it or any statute, proclamation, rule, code,
        regulation or ordinance replacing it;</div>
        <div>(h) all monetary amounts are in Australian currency;</div>
        <div>(i) a reference to time refers to Australian Eastern Standard Time;</div>
        <div>(j) "includes" is not a word of limitation; no rule of construction applies to the disadvantage of a
        party
        because these Terms are prepared by (or on behalf of) that party;</div>
        <div>(k) a reference to any thing is a reference to the whole and each part of it;</div>
        <div>(l) a reference to a group of persons is a reference to all of them collectively and to each of them
        individually; and</div>
        <div>(m) a reference to a document includes all amendments or supplements to, or replacements or novations of,
        that document.</div>
    </div>

    <hr/>
    <div class="terms-heading">2. Terms and Conditions</div>
    <div class="terms-block">

        <div>2.1. Access to and use of this Website and mobile phone application is governed by these Terms.</div>
        <div>2.2. GHR periodically reviews the Terms and reserves the right to change the Terms, without any notice to
        you, by updating this document. You should review this document, as available on the Website and mobile
        phone applications, regularly as any changes to the Terms take immediate effect from the date of the
        publication on this document. Your continued use of the Website and/or mobile phone applications after any
        such changes are made will be deemed to constitute your acceptance of those changes.</div>
        <div>2.3. You acknowledge that any hyperlinks or other redirection tools taking you to other operated by third
        parties that appear on the Website or mobile phone applications ("Third Party Sites") are not controlled by
        GHR and do not form part of the Website or mobile phone applications. You agree that you will not hold GHR
        liable or in any way accountable for anything that occurs on any Third Party Site.</div>

    </div>

    <hr/>
    <div class="terms-heading">3. Acceptance</div>
    <div class="terms-block">

        <div>3.1. In order to use this Website or mobile phone applications and make a Booking, you must first agree
        to
        the Terms.</div>
        <div>3.2. You accept the Terms by clicking to accept/agree to the Terms.</div>
        <div>3.3. Notwithstanding clause 3.2, you may not accept the Terms or access the Website or mobile phone
        applications if:</div>
        <div> (a) you are not of legal age to form a binding contract with GHR; or</div>
        <div> (b) you are a person barred from entering into contracts under legislation,</div>
        <div>in which case, you must immediately leave the Website or mobile phone applications.</div>
        <div>3.4. By accepting the Terms, you agree that this is clear and unequivocal proof that the Terms are not
        unconscionable, that there is no unfair bargaining power or position, that there is no duress, and that you
        have carefully read and understood the Terms.</div>
        <div>3.5. If you do not understand any part of the Terms you should seek independent legal advice.</div>

    </div>

    <hr/>
    <div class="terms-heading">4. User Details</div>
    <div class="terms-block">
        <div>4.1. You will be required to register your personal information, including your name, address, phone
        number and payment details on the Website or mobile phone applications in order to make a Booking.</div>
        <div>4.2. You confirm that you are at least 18 years of age and possess the legal authority to use the Booking
        System in accordance with these Terms. You also agree that all information supplied by you in using the
        Website or mobile phone applications and the Booking System will be true and accurate at all times.</div>
        <div>4.3. GHR may refuse to deliver an order at its own discretion.</div>
    </div>

    <hr/>
    <div class="terms-heading">5. Booking System</div>
    <div class="terms-block">
        <div>5.1. To make a Booking you must use the Booking System to request a Booking. You will receive a Booking
        confirmation, that constitutes the contractual relationship between you and GHR that you are deemed to have
        entered into for the provision of the Trade Services.</div>
        <div>5.2. GHR will make your contact details, location, requested time and the scope of the Trade Service
        available to the most appropriate Tradie.</div>
        <div>5.3. You are able to request a preferred booking window. GHR will take your preference into account when
        facilitating the Trade Service, however a specific booking window cannot be guaranteed and will depend on
        availability.</div>
        <div>5.4. If the requested Booking cannot be facilitated, GHR will arrange an alternative time for the Trade
        Service with you and a Tradie.</div>
        <div>5.5. Once a booking window is confirmed we will make all efforts to attend within this window, however
        cannot guarantee that we will arrive within this timeframe. Should this be the case, GHR will contact you
        to advise.</div>
        <div>5.6. You are not permitted to engage the Trade Services of a Tradie other than through the Booking System
        on the Website or mobile phone applications.</div>
        <div>5.7. The Booking System is provided on the Website or mobile phone applications to enable you to make
        legitimate Bookings and for no other purposes.</div>
        <div>5.8. Any speculative, false or fraudulent Booking is prohibited. You agree that the Booking System will
        only be used to make legitimate Bookings for you or another person for whom you are legally authorised to
        act. You acknowledge that abuse of the Booking System may result in you being denied access to the Booking
        System.</div>
        <div>5.9. GHR has the right at any time to add, change or withdraw functions available on the Website or
        mobile
        phone applications at its own discretion.</div>
    </div>

    <hr/>
    <div class="terms-heading">6. Pricing and Payment</div>
    <div class="terms-block">
        <div>6.1. Upon booking a trade service, you will be provided with an estimated price to complete the job based
        on information you provided and our experience delivering these services. </div>
        <div>6.2. The Tradie attending to complete your Trade Service will conduct an assessment and will either
        confirm that the job can be completed for the price estimate provided or advise you of any additional costs
        required to complete the job.</div>
        <div>6.3. Either before commencing or during the job, you will be required to sign off acceptance of the final
        price, this sign off constitutes agreeance that you will make payment in full upon completion of the job.
        </div>
        <div>6.4. If you do not agree to the final price, this will constitute a cancellation and a cancellation fee
        will apply as per clause 7. </div>
        <div>6.5. Payment will be made by you via production of a valid Credit Card (VISA / Mastercard) and processed
        before the Tradie leaves the property.</div>
        <div>6.6. GHR, will issue you with a tax invoice once payment has been made.</div>
    </div>

    <hr/>
    <div class="terms-heading">7. Changes and Cancellations</div>
    <div class="terms-block">
        <div>7.1. You can cancel or amend a Booking free of charge, up to 24 hours before the Trade Service is
        scheduled to begin.</div>
        <div>7.2. If you cancel a Booking within 24 hours before the Trade Service is scheduled to begin, you may have
        to pay a cancellation fee of $65.</div>
        <div>7.3. The Agreement shall expire once the Trade Services under the Booking have been performed.</div>
    </div>

    <hr/>
    <div class="terms-heading">8. Trade Services</div>
    <div class="terms-block">
        <div>8.1. GHR operates the Website and mobile phone applications as a solution for the Trade Services and
        provides you with the platform to purchase the Trade Services.</div>
        <div>8.2. GHR uses their best efforts to monitor the Trade Services offered by a Tradie. Any issues regarding
        safety, quality, condition or description should be notified to GHR, who will then attempt to resolve the
        issue as per our Dispute Resolution Policy.</div>
        <div>8.3. All bookings are subject to Tradie availability.</div>
        <div>8.4. GHR will only complete Trade Services within the property boundary.</div>
        <div>8.5. GHR will not complete Trade Services on areas or equipment reasonably considered to be the province
        and responsibility of utility providers such as gas or water meters, gas pipelines, electricity cables and
        grids or water mains.</div>
        <div>8.6. GHR will only complete Trades Services at a property if there is a person 18 years of age or older
        at
        the property present to provide signed authority to proceed with works and also provide payment for works
        upon completion.</div>
        <div>8.7. Home Inspections refers to the provision of a Pre Purchase Building and Pest Inspection,
        which compromises the non-invasive assessment of the building. The inspection will not report on pools,
        spas, ponds etc.
        The customer is advised to obtain the services of a pool specialist to carry out an inspection and report.
        </div>
    </div>

    <hr/>
    <div class="terms-heading">9. Dispute Resolution Policy</div>
    <div class="terms-block">
        <div>9.1. GHR will use reasonable endeavours to resolve any dispute concerning the use of the Website or
        mobile
        phone applications.</div>
        <div>9.2. GHR will use reasonable endeavours to resolve any dispute concerning the Service Delivery provision
        of a Tradie.</div>
        <div>9.3. Where GHR are unable to resolve disputes in relation to the Trade Services carried out by a Tradie
        or
        any other issue will be referred, where appropriate, to external dispute resolution services or
        authorities.</div>
    </div>

    <hr/>
    <div class="terms-heading">10. Security of Payment</div>
    <div class="terms-block">        
        <div>10.1. We have taken all practical steps from both a technical and systems perspective to ensure that all
        your information is well protected. A secure payment gateway is used to process all transactions and credit
        card details. GHR does not give any warranty or make any representation regarding the strength or
        effectiveness of the secure payment gateway and is not responsible for events arising from unauthorised
        access to your information.</div>
    </div>

    <hr/>
    <div class="terms-heading">11. Privacy</div>
    <div class="terms-block">
        <div>11.1. In this clause Personal Information means information or an opinion (including information or an
        opinion forming part of a database) whether true or not, and whether recorded in a material form or not,
        about an individual whose identity is apparent or can reasonably ascertained, from the information or
        opinion.</div>
        <div>11.2. GHR collects and stores Personal Information you enter. Personal Information collected by GHR will
        be maintained in accordance with our Privacy Policy available on the website. </div>
        <div>11.3. You agree that GHR will disclose your Personal Information, including your name and address to the
        Tradie for the Tradie to carry out the Trade Services.</div>
        <div>11.4. Both parties must abide by all relevant Australian Privacy Principles set out in the Privacy Act
        1998 in their collection, use, disclosure and storage of Personal Information.</div>
    </div>

    <hr/>
    <div class="terms-heading">12. Intellectual Property</div>
    <div class="terms-block">
        <div>12.1. The Site Content is protected by copyright and GHR reserves all Intellectual Property Rights which
        may subsist in the Site Content and/or the Website and/or mobile phone applications.</div>
        <div>12.2. By accepting the Terms, you are granted a non-exclusive licence to:</div>
        <div> (a) view the Website and/or mobile phone applications;</div>
        <div> (b) print pages from the Website and/or mobile phone applications in its original form; and</div>
        <div> (c) download any material from the Website and/or mobile phone applications for caching purposes only.
        </div>
        <div>12.3. You must not, without GHR’s prior written consent which may be withheld at its absolute discretion:
        </div>
        <div> (a) copy, republish, reproduce, duplicate or extract Site Content;</div>
        <div> (b) redistribute, sell, rent or licence any Site Content; or</div>
        <div> (c) edit, modify or vary the Site Content.</div>
    </div>
    
    <hr/>
    <div class="terms-heading">13. Acceptable Use</div>
    <div class="terms-block">
        <div>13.1. You must not use, or cause this Website or mobile phone applications to be used, in any way which:
        </div>
        <div> (a) breaches any of these Terms;</div>
        <div> (b) infringes GHR’s or any third party’s Intellectual Property Rights;</div>
        <div> (c) is fraudulent, illegal or unlawful; or</div>
        <div> (d) causes impairment of the availability or accessibility of the Website or mobile phone applications.
        </div>
        <div>13.2. You must not use, or cause this Website or mobile phone applications to be used, as a medium which
        stores, hosts, transmits sends or distributes any material which consists of:</div>
        <div> (a) spyware;</div>
        <div> (b) computer viruses;</div>
        <div> (c) Trojans;</div>
        <div> (d) worms;</div>
        <div> (e) keystroke loggers; or</div>
        <div> (f) any other malicious computer software.</div>

    </div>

    <hr/>
    <div class="terms-heading">14. Indemnity and Liability</div>
    <div class="terms-block">
        <div>14.1. General indemnity</div>
        <div>You agree to indemnify GHR, on demand, against any claim, action, damage, loss, liability, cost, charge,
        expense or payment which GHR may pay, suffer, incur or are liable for, in relation to any act you do or
        cause to be done, in breach of these Terms.</div>
        <div>14.2. General limitation of liability</div>
        <div>We will not be liable to you in contract, tort or equity in relation to any direct, indirect or
        consequential loss you incur in relation to the contents or use of or reliance on Site Content or otherwise
        in connection with the Website or mobile phone applications.</div>
        <div>14.3. Trade Services Liability</div>
        <div>To the extent permitted by law, GHR will not be responsible and will be excluded from all liability, for
        any loss or damage whatsoever (including personal injury, loss of life and damage to property) that you or
        another person may suffer in connection with the offer, advice or supply of (or default in supplying) the
        Trade Services.</div>
        <div>14.4. Information accuracy</div>
        <div>You acknowledge and agree that some of the Site Content may be provided by way of blogs or comments made
        by other users of the Website or mobile phone applications, and that GHR does not accept any liability for
        the accuracy of such information or your reliance on the same. The Site Content is provided to you as
        general information only and is not intended to substitute or replace the advice of a duly qualified
        professional (where applicable).</div>
        <div>14.5. Acceptance</div>
        <div>By using this Website or mobile phone applications, you agree and accept that the indemnity and
        limitations of liability provided in this clause 15 are reasonable.</div>
    </div>
    
    <hr/>
    <div class="terms-heading">15. Warranties and Representations</div>
    <div class="terms-block">

        <div>15.1. The use of this Website or mobile phone applications is at your own risk. The Site Content and
        everything from the Website or mobile phone applications is provided to you on an “as is” and “as
        available” basis without warranty or condition of any kind.</div>
        <div>15.2. None of GHR’s affiliates, directors, officers, employees, agents, contributors, third party content
        providers or licensors make any express or implied representation or warranty about the Site Content or
        Website or mobile phone applications.</div>
        <div>15.3 GHR warrant and certify all works as per statutory requirements. A 12-month workmanship warranty
        will
        be provided on all works completed, with the exception of drain clearing as per 15.10.</div>
        <div>15.4 Products and materials supplied by GHR will be covered by manufacturer’s warranty conditions, as
        provided by the manufacturer. GHR does not accept any responsibility for faulty products or materials.</div>
        <div>15.5 Any materials supplied by the customer which do not meet required standards will not be installed.
        No
        warranty applies to any parts supplied by the customer.</div>
        <div>15.6 All warranty claims should be reported to GHR immediately and will only be accepted as warranty
        rectification works, once a site inspection has been completed, to determine eligibility. Site visits to
        non - eligible claims, maybe charged as a service call.</div>
        <div>15.7 Warranty inspections and repairs will only be completed during business hours.</div>
        <div>15.8 Clearing of blocked drains and inspection of blocked and damaged drains is conducted at the risk of
        the Customer. GHR will not be held responsible for the location or condition of existing drainage systems,
        nor the products or materials located within the drainage systems.</div>
        <div>15.9 Investigation and maintenance of drains involves insertion of specialised drainage equipment into
        existing drains, in order to conduct works or investigations. Customer accepts responsibility for any
        damage caused to the plumbing drainage system that maybe caused while maintenance works to drainage system
        is conducted.</div>
        <div>15.10 Any damage caused to specialised drainage equipment, caused through investigation or maintenance of
        the drainage system will be the responsibility of the Customer. Recovery of equipment lost or lodged within
        the drainage system will be at the expense of the Customer.</div>
        <div>15.11 The Customer acknowledges that pipes affected by root growth and or blockages are generally damaged
        as a result of such interference. The removal of root growth and / or blockages alone carries no warranty
        that similar problems will not recur unless permanent repairs are</div>
        <div>15.12 Any electrical works required to upgrade the property to Australian and New Zealand wiring
        standards
        will be at an additional cost, which will be communicated by the Tradie as soon as it is identified.</div>
        <div>15.13 Any warranty relating to a Pre-Purchase Building and Pest Inspection conducted by GHR is covered
        within the specific report provided to you.</div>
    </div>

    <hr/>
    <div class="terms-heading">16. Environmental and Occupational Health & Safety</div>
    <div class="terms-block">

        <div>16.1 GHR will not provide Trade Services or may be limited where in the opinion of the Tradie, there is a
        real risk of danger to the health and safety of any person, or there is a real risk of causing significant
        damage to the environment.
        This may include situations such as wet roofs generally, 2-storey buildings (or buildings with a high-pitched roof) or the presence of asbestos.
        </div>

    </div>

    <hr/>
    <div class="terms-heading">17. Emergency Jobs</div>
    <div class="terms-block">

        <div>17.1 Where the customer requires assistance to enter the property,
        satisfactory proof of identity will be required by the attending Tradie.</div>
        <div>17.2 An Emergency Job provides customers with fast response
        assistance in the event of an emergency. On site assistance is provided by Tradies who provide a combination
        of services including assessment, repairs and temporary procedures that make the property safe and secure
        where they are unable to fix the problem. Service provided also includes advice on any further works
        required.</div>
        <div>17.3 A Tradie will attend to provide the services as soon as
        practicable, but a response time is not guaranteed and will vary depending on location, demand for service,
        amongst other things. GHR will not be liable for any failure or delay in providing service, where the
        failure or delay arises from causes beyond the reasonable control of GHR, including without limitation:
        Major Disasters or Events, adverse weather conditions, unavailability of materials and otherwise where the
        relevant Service is not reasonably available.</div>
        <div>17.4 An Emergency Job provides for up to one hour of labour from the
        time the Tradie arrives at the property. Where specialised (Example: Drain Camera, or high-pressure
        waterjet) or more extensive repairs are needed, the customer may request the Tradie to arrange an obligation
        free quote. Any further repairs or works required will incur an additional cost.</div>
        <div>17.5 Works will only be performed by the Tradie within the property
        boundary that they are attending. Where a problem occurs, originates or extends outside the boundaries of
        the property, repairs and Make Safe Procedures will be limited to work which can be carried out within the
        boundaries of the property.</div>
        <div>17.6 The following are not covered in relation to Emergency Jobs
        </div>
        <div> (a) The need for replacement parts</div>
        <div> (b) Major Structural Damage</div>
        <div> (c) Area Wide Disruption to Essential Services</div>
        <div> (d) Disruption to essential services as the result of disconnection
        by the relevant authority or service provider</div>
        <div> (e) Major Disasters</div>
    </div>

    <hr/>
    <div class="terms-heading">18. General Provisions</div>
    <div class="terms-block">
        <div>18.1 Any provision of, or
        the application of any provision of these Terms which is prohibited in any
        jurisdiction is, in that jurisdiction, ineffective only to the extent of that prohibition.</div>
        <div>18.2 Any provision of, or
        the application of any provision of these Terms which is void, illegal or
        unenforceable in any jurisdiction does not affect the validity, legality or enforceability of that
        provision in any other jurisdiction or of the remaining provisions in that or any other jurisdiction.</div>
        <div>18.3 If a clause is void,
        illegal or unenforceable, it may be severed without affecting the
        enforceability of the other provisions in these Terms.</div>
        <div>18.4 These Terms shall be
        governed by and construed in accordance with the law for the time being
        applicable to Victoria and you agree to submit to the non-exclusive jurisdiction of the Courts of Victoria.
        </div>
    </div>
    <hr/>
</div>
