<div class="login-container">
    <div class="password-container">
        <div class="login-title">
            This content is password protected. To view it, please enter your password below.
        </div>
        <div class="login-box">
            <div class="password-label">
                <label for="password">Password:</label>
            </div>
            <form class="password-field">
                <input data-testid="loginflow-password" [formControl]="password" matInput id="password" type="password"
                    (keyup.enter)="submit()">
            </form>
            <div class="login-button-container">
                <button (click)="submit()" data-testid="loginflow-submitButton" [disabled]="loginButtonDisabled"
                    mat-button>Enter</button>
            </div>
        </div>
        <div class="login-failed-message" *ngIf="showLoginErrorMessage">
            {{loginErrorMessage}}
        </div>
    </div>
</div>