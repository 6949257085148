<div style="display: grid;">

    <div class="home-container" [ngSwitch]="clientID">

        <!-- 
            2023-06-07 - MM
            because we keep not having time for maintenance, I keep having to hack in new home page designs at the last minute to support different clients 
            doing this AGAIN, because the standard asset format we'd decided on for all future clients isn't being adhered to, and it's already after hours and half an hour before release
            so, now we have an app-home-directworks component because I don't have time to sort this out dynamically.
            TODO: just have one Home component, and swap out the CSS based on client. Also standardise as many clients as possible into a single asset format to reduce the amount of different CSS configurations. hard to avoid for TBS, but should be possible for other clients
            TODO: bin derrimut, since they're no longer a client. also bin the GRB setup with the friendly giant because we built it before the standardised format, and then never won them as a client. 
        -->

        <app-home-tbs *ngSwitchCase="clients.TheBodyShop"></app-home-tbs>
        <app-home-tbs *ngSwitchCase="clients.TheBodyShopNZ"></app-home-tbs>
        <app-home-derrimut *ngSwitchCase="clients.DerrimutGyms"></app-home-derrimut>
        <app-home-basic *ngSwitchCase="clients.AHA"></app-home-basic>
        <app-home-basic *ngSwitchCase="clients.LIDS"></app-home-basic>
        <app-home-basic *ngSwitchCase="clients.VIVA"></app-home-basic>
        <app-home-directworks *ngSwitchCase="clients.OneTouchDirect"></app-home-directworks>
        <app-home-directworks *ngSwitchCase="clients.RRRDW"></app-home-directworks>
        <app-home-directworks *ngSwitchCase="clients.EasyLink"></app-home-directworks>
        <app-home-default *ngSwitchDefault></app-home-default>
    </div>

    <div class="triage-form-container" id="app-triage" data-testid="triage-form-container">
        <div class="triage-title">Book a Service</div>
        <app-triage-dynamic [contactDetailsFieldGroups]="contactDetailsFieldGroups$ | async"
            [locationAndServiceTypeFieldGroups]="locationAndServiceTypeFieldGroups$ | async">
        </app-triage-dynamic>
    </div>

</div>
<hr>