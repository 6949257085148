import { Component } from '@angular/core';

@Component({
  selector: 'app-home-directworks',
  templateUrl: './home-directworks.component.html',
  styleUrls: ['./home-directworks.component.css']
})
export class HomeDirectworksComponent {

}
