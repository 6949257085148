<div class="home-container">

    <div class="home-block-colour">
        
        <div class="home-title">

            Trade<br>Services
            
            <div class="home-button-container">
                <button class="get-started-button" (click)="scroll('app-triage')" style="cursor: pointer">Get Started</button>
            </div>
        </div>
        
    </div>
    <div class="home-tradie-img">
        <img src="assets/img/{{clientID}}/tradie.png">
    </div>

</div>