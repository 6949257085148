import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

// firestore
import { FirestoreModule } from '@angular/fire/firestore'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

// angular material
import { MatLegacyFormFieldModule as MatFormFieldModule  } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

// angular libraries
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// services
import { FirestoreDataService } from './firestore-data.service';
import { GlobalsService } from './globals.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { JobDataService } from './job-data.service';

// 3rd party libraries
import { ToastrModule } from 'ngx-toastr';

// components
import { CompleteComponent } from './complete/complete.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TermsComponent } from './terms/terms.component';
import { LoginComponent } from './login/login.component';
import { HomeTbsComponent } from './home-tbs/home-tbs.component';
import { HomeDerrimutComponent } from './home-derrimut/home-derrimut.component';
import { HomeDefaultComponent } from './home-default/home-default.component';
import { DynamicFormQuestionComponent } from './forms/dynamic-form-question/dynamic-form-question.component';
import { TriageDynamicComponent } from './triage-dynamic/triage-dynamic.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { PriorityPickerCollapsibleComponent } from './priority-picker-collapsible/priority-picker-collapsible.component';
import { PriorityInfoDialogComponent } from './priority-info-dialog/priority-info-dialog.component';

// directives
import { StepperScrollerDirective } from './stepper-scroller.directive';
import { AutocompleteAddressFormComponent } from './autocomplete-address-form/autocomplete-address-form.component';
import { HomeBasicComponent } from './home-basic/home-basic.component';
import { HomeDirectworksComponent } from './home-directworks/home-directworks.component';

 
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ToolbarComponent,
    CompleteComponent,
    PrivacyComponent,
    TermsComponent,
    LoginComponent,
    StepperScrollerDirective,
    HomeTbsComponent,
    HomeDefaultComponent,
    DynamicFormQuestionComponent,
    TriageDynamicComponent,
    DatetimePickerComponent,
    PriorityPickerCollapsibleComponent,
    PriorityInfoDialogComponent,
    HomeDerrimutComponent,
    AutocompleteAddressFormComponent,
    HomeBasicComponent,
    HomeDirectworksComponent
  ],
  imports: [
    BrowserModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    FirestoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDialogModule,
    RouterModule,
    FormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatTooltipModule,
  ],
  providers: [
    FirestoreDataService,
    DatePipe,
    GlobalsService,
    AuthService,
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
