<!-- form group -->
<div class="form-group">

  <div class="triage-prompt">
    Please start typing your address and our booking platform will locate it for you. 
  </div>

  <div class="address-search-container">
    <mat-form-field style="width: 100%;">
      <input 
      matInput 
      type="text" 
      class="address-search-box" 
      (keydown.enter)="$event.preventDefault()"
      placeholder="Search Location" 
      type="text"
      [(ngModel)]="searchQuery"
      #addressSearch />
    </mat-form-field>
  </div>

  <div class="triage-prompt">
    After selecting your address, it will be prefilled here. 
  </div>

  <form [formGroup]="addressForm" class="address-form-fields">


    <mat-form-field class="form-item" appearance="fill">
      <mat-label>Unit</mat-label>
      <input data-testid="bookingdetails-unit" matInput formControlName="unit">
    </mat-form-field>

    <mat-form-field class="form-item" appearance="fill">
      <mat-label>Street Address</mat-label>
      <input data-testid="bookingdetails-streetAddress" matInput required formControlName="streetAddress">
    </mat-form-field>


    <mat-form-field class="form-item" appearance="fill">
      <mat-label>Suburb</mat-label>
      <input data-testid="bookingdetails-suburb" matInput required formControlName="suburb">
    </mat-form-field>
  
    <mat-form-field class="form-item" appearance="fill">
      <mat-label>State</mat-label>
      <input data-testid="bookingdetails-state" matInput required formControlName="state">
    </mat-form-field>
  
    <mat-form-field class="form-item" appearance="fill">
      <mat-label>Postcode</mat-label>
      <input data-testid="bookingdetails-postcode" matInput required formControlName="postcode">
    </mat-form-field>
  </form>


</div>