import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, Subscription } from 'rxjs';

import { QuestionControlService } from '../question-control.service';
import { InputFieldGroup } from '../question.service';
import { DropdownService, DropdownItem } from '../dropdown.service';
import { Clients, FormFieldTypes, JobChannelType, PriorityTypes, JobNoteTypes } from '../globals.service';
import { FirestoreDataService, IJobSite, } from '../firestore-data.service';

import { ToastrService } from 'ngx-toastr';
import { compressAccurately } from 'image-conversion';

import { environment } from 'src/environments/environment';
import { HomeServiceItem, JobBookingWindow, JobContact, JobData, JobDataService, JobTimeWindow, TriageResponseItem } from '../job-data.service';
import { UtilityService } from '../utility.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-triage-dynamic',
  templateUrl: './triage-dynamic.component.html',
  styleUrls: ['./triage-dynamic.component.css']
})
export class TriageDynamicComponent implements OnInit {

  public videoServiceURL: string = environment.videoServiceURL;

  @Input() contactDetailsFieldGroups: InputFieldGroup[] | null = [];
  @Input() locationAndServiceTypeFieldGroups: InputFieldGroup[] | null = [];

  // form groups
  contactDetailsForm!: FormGroup;
  locationAndServiceTypeForm!: FormGroup;
  dateTimeForm = new FormGroup({
    appointmentDate: new FormControl('', [Validators.required]),
    appointmentTime: new FormControl('', [Validators.required])
  });

  addressForm = new FormGroup({
    streetAddress: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required]),
    unit: new FormControl('')
  });

  // environment variables
  clientID: number = environment.clientID;
  activeFormFields: any = environment.activeDDLOptions;
  sendsSupervisorEmail = environment.sendsSupervisorEmailForEmergencyJobs;
  usesPriorityNotDateTime: boolean = environment.usesPriorityNotDateTime;
  whenTitle: string = this.usesPriorityNotDateTime ? 'Priority' : 'When';
  enforcedImageAttachment: boolean = environment.enforcedImageAttachment;
  loadsStoreDataFromFirebase: boolean = environment.loadsStoreDataFromFirebase;
  usesManualAddressEntryForm: boolean = environment.mapsConfig.usesMapsAPI;
  contactOptions: any = environment.contactOptions;

  // data caching
  public fireStoreDataLoaded: boolean = false;
  selectedJobSite: IJobSite | undefined = undefined;
  jobSiteList: IJobSite[] = [];
  jobSiteObservable: Subscription | null = null;
  selectedPriority: number = this.priorityTypes.NoPriority;
  changedFieldValueMap: Map<string, string>;

  // booking photos
  bookingPhotos: Array<BookingPhoto> = [];
  isSubmitting: boolean = false;

  // booking videos
  private readonly MAX_BOOKING_VIDEOS: number = 3;
  public readonly MAX_BOOKING_VIDEO_SIZE_MB: number = 128;
  private readonly MAX_BOOKING_VIDEO_SIZE_BYTES: number = this.MAX_BOOKING_VIDEO_SIZE_MB * 1024 * 1024;
  public bookingVideos: Array<BookingVideo> = [];
  public uploadingBookingVideos: boolean = false;

  // layout
  stepperOrientation: Observable<StepperOrientation>;

  constructor(
    private questionControlService: QuestionControlService,
    private dataService: FirestoreDataService,
    private formFieldService: DropdownService,
    private utilityService: UtilityService,
    private jobDataService: JobDataService,
    public priorityTypes: PriorityTypes,
    private clients: Clients,
    private toastr: ToastrService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private httpClient: HttpClient
  ) {
    // switch the stepper from horizontal to vertical based on screen width
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 670px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.changedFieldValueMap = new Map<string, string>();
  }

  ngOnInit(): void {

    // set up forms via question control service service
    this.contactDetailsForm = this.questionControlService.toFormGroup(this.contactDetailsFieldGroups as InputFieldGroup[]);
    this.locationAndServiceTypeForm = this.questionControlService.toFormGroup(this.locationAndServiceTypeFieldGroups as InputFieldGroup[]);

    // get job site data from data service
    if (environment.loadsStoreDataFromFirebase) {

      // 
      this.jobSiteObservable = this.dataService.fetchStores().subscribe(jobSiteData => {

        const jobSiteList = jobSiteData[0];
        if (jobSiteList) {
          this.jobSiteList = JSON.parse(jobSiteList['data']);
        } else {
          // todo: handle error
        }

        // set up DDL options in service
        this.formFieldService.loadDDLs(this.jobSiteList);

        // iterate over FormField types
        Object.values(FormFieldTypes).forEach(x => {
          // if form field type is active for this client
          if (this.activeFormFields[x]) {
            // populate appropriate DDL
            this.setDDLOptionsUnfiltered(this.getQuestionIndexPathByKey(x), this.formFieldService.getUnfilteredDDL(x));
          }
        })

        // set up service type descriptions
        // todo: rewrite this to be generic, in case we add descriptions for any other DDL
        if (this.activeFormFields[FormFieldTypes.ServiceType]) {
          this.setDDLOptionDescriptions(this.getQuestionIndexPathByKey(FormFieldTypes.ServiceType), this.formFieldService.getServiceTypeDescriptions())
        }

        // ensure firestore data is loaded before enabling controls
        Object.keys(this.locationAndServiceTypeForm.controls).forEach(key => {
          // MM - all controls on this step are disabled until firestore data is loaded, so we can guarantee required elements are clickable in Playwright tests
          // lot number is an exception to this rule - it gets enabled after FormFieldTypes.BuildingName is selected, so don't enable it here
          if (key != FormFieldTypes.LotNumber)
            this.locationAndServiceTypeForm.controls[key].enable();
        });

      });
    } else {
      // set up service type only, since that's not stored in Firebase
      if (this.activeFormFields[FormFieldTypes.ServiceType]) {
        this.formFieldService.loadServiceTypes();
        this.setDDLOptionsUnfiltered(this.getQuestionIndexPathByKey(FormFieldTypes.ServiceType), this.formFieldService.getUnfilteredDDL(FormFieldTypes.ServiceType));
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe from data service observable
    if (this.jobSiteObservable) {
      this.jobSiteObservable.unsubscribe();
    }
  }

  /*
    ====================
    DDL HANDLING METHODS
    ====================
  */

  getQuestionIndexPathByKey(key: string): QuestionIndexPath {

    /*
      MM - 2022-12-23:
        - given a key (DDLType), find the 3 indexes which describe the location of that DDL: (field group index, question row index, question index) 
        - this is not very computationally efficient, but we should theoretically never have enough questions in our fieldGroup that it causes a problem
        - currently this is just set up to search the locationAndServiceTypeFieldGroups, but easy enough to refactor by passing in a field group as an arg
    */
    const numFieldGroups: number = this.locationAndServiceTypeFieldGroups ? this.locationAndServiceTypeFieldGroups.length : 0;

    var fieldGroupIndex: number = -1;
    var questionRowIndex: number = -1;
    var questionIndex: number = -1;

    for (let i = 0; i < numFieldGroups; ++i) {
      // for each field group, get question rows
      const questionRows = this.locationAndServiceTypeFieldGroups![i].questionRows;

      for (let j = 0; j < questionRows.length; ++j) {
        // for each question row, get questions
        const questions = questionRows[j];

        for (let k = 0; k < questions.length; ++k) {
          // for each question
          const question = questions[k];

          if (question.key == key) {
            // if key matches, log indexes to retrieve this question
            fieldGroupIndex = i;
            questionRowIndex = j;
            questionIndex = k;
          }
        }
      }
    }

    return ({
      exists: fieldGroupIndex != -1,
      fieldGroupIndex: fieldGroupIndex,
      questionRowIndex: questionRowIndex,
      questionIndex: questionIndex
    });
  }

  setDDLOptionsUnfiltered(indexPath: QuestionIndexPath, options: DropdownItem[]) {
    // set the available DDL options for a given question
    if (indexPath.exists) {
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].options = options;
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].filteredOptions = options;
    }
  }

  setDDLOptionDescriptions(indexPath: QuestionIndexPath, optionDescriptions: DropdownItem[]) {
    // set the descriptions for a list of DDL options
    if (indexPath.exists) {
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].optionDescriptions = optionDescriptions;
    }
  }

  getDDLOptionsUnfiltered(indexPath: QuestionIndexPath) {
    // get the full, unfiltered list of options for a given DDL
    if (indexPath.exists) {
      return this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].options;
    }
    return null;
  }

  removeDDLFilter(indexPath: QuestionIndexPath) {
    if (indexPath.exists) {
      // fetch unfiltered options list
      const unfilteredOptions = this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].options;
      // set filtered options to be equal to unfiltered options
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].filteredOptions = unfilteredOptions;
    }
  }

  applyDDLFilter(indexPath: QuestionIndexPath, filteredOptions: DropdownItem[]) {
    // set a filtered list of options into a DDL
    if (indexPath.exists) {
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].filteredOptions = filteredOptions;
    }
  }

  setSelectedDDLOption(indexPath: QuestionIndexPath, selection: string) {
    // set the selected option for a given DDL
    if (indexPath.exists) {
      this.locationAndServiceTypeFieldGroups![indexPath.fieldGroupIndex].questionRows[indexPath.questionRowIndex][indexPath.questionIndex].value = selection;
    }
  }

  applyDDLSelectionChange(key: string, value: any) {

    this.changedFieldValueMap.set(key, value);

    // get path to the DDL that was changed
    const indexPath = this.getQuestionIndexPathByKey(key);
    if (!indexPath.exists) {
      // todo: handle failure
      return;
    }

    // filter lists according to change
    this.formFieldService.applyDDLSelectionChange(key, value, this.changedFieldValueMap);

    var ddlsToRefresh: FormFieldTypes[] = [];
    switch (key) {

      case FormFieldTypes.Country:

        // when Country changes, filter these lists
        ddlsToRefresh = [FormFieldTypes.Brand, FormFieldTypes.Region, FormFieldTypes.JobSite, FormFieldTypes.ClubType];
        this.applyDDLFilterByFormFieldType(ddlsToRefresh);
        break;

      case FormFieldTypes.Brand:

        // when Brand changes, filter these lists
        ddlsToRefresh = [FormFieldTypes.Region, FormFieldTypes.JobSite];
        this.applyDDLFilterByFormFieldType(ddlsToRefresh);
        break;
      case FormFieldTypes.ClubType:

        // when Brand changes, filter these lists
        ddlsToRefresh = [FormFieldTypes.Region, FormFieldTypes.JobSite];
        this.applyDDLFilterByFormFieldType(ddlsToRefresh);
        break;

      case FormFieldTypes.Region:

        // when Region changes, filter these lists
        ddlsToRefresh = [FormFieldTypes.ClubType, FormFieldTypes.Brand, FormFieldTypes.Country, FormFieldTypes.JobSite];

        ddlsToRefresh.forEach(ddlType => {
          // if this DDLType is active for this client
          if (this.activeFormFields[ddlType]) {
            // apply filter
            this.applyDDLFilter(this.getQuestionIndexPathByKey(ddlType), this.formFieldService.getFilteredDDL(ddlType));
            if (this.formFieldService.filteredListContainsOneItem(ddlType)) {
              var jobSiteValue = this.formFieldService.getFilteredDDL(ddlType)[0].value
              // if list is filtered down to 1 item, select it
              this.locationAndServiceTypeForm.controls[ddlType].setValue(jobSiteValue);
              /* S.R 23/02/2023 - Calling this so all forms are populated */
              this.applyDDLJobSiteChange(jobSiteValue);
            }
          }
        });

        // reset country list options, so that the user doesn't get locked out of country selection
        if (this.activeFormFields[FormFieldTypes.Country]) {
          this.removeDDLFilter(this.getQuestionIndexPathByKey(FormFieldTypes.Country));
        }

        // reset brand list options, so that the user doesn't get locked out of brand selection
        if (this.activeFormFields[FormFieldTypes.Brand]) {
          this.removeDDLFilter(this.getQuestionIndexPathByKey(FormFieldTypes.Brand));
        }

        if (this.activeFormFields[FormFieldTypes.ClubType]) {
          this.removeDDLFilter(this.getQuestionIndexPathByKey(FormFieldTypes.ClubType));
        }

        break;

      case FormFieldTypes.BuildingName:
        // 
        ddlsToRefresh = [FormFieldTypes.LotNumber];
        this.applyDDLFilterByFormFieldType(ddlsToRefresh);
        this.locationAndServiceTypeForm.controls[FormFieldTypes.LotNumber].setValue('');
        this.locationAndServiceTypeForm.controls[FormFieldTypes.LotNumber].enable();
        break;

      case FormFieldTypes.ServiceType:
        // no filtering required at this stage
        break;

      case FormFieldTypes.LotNumber:
        this.applyDDLLotNumberChange(value);
        break;
      case FormFieldTypes.JobSite:

        this.applyDDLJobSiteChange(value);

        // no filtering, or we'd end up with all our DDLs only having one item in them
        // instead, just select the relevant value from all active DDLs, and populate relevant contact details
        break;
    }
  }

  applyDDLFilterByFormFieldType(ddlsToRefresh: FormFieldTypes[]) {
    ddlsToRefresh.forEach(ddlType => {
      // if this DDLType is active for this client
      if (this.activeFormFields[ddlType]) {
        // apply filter
        this.applyDDLFilter(this.getQuestionIndexPathByKey(ddlType), this.formFieldService.getFilteredDDL(ddlType));
        if (this.formFieldService.filteredListContainsOneItem(ddlType)) {
          // if list is filtered down to 1 item, select it
          var jobSiteValue = this.formFieldService.getFilteredDDL(ddlType)[0].value
          this.locationAndServiceTypeForm.controls[ddlType].setValue(jobSiteValue);

        }
      }
    });
  }

  applyDDLLotNumberChange(value: string) {
    const selectedBuildingName = this.locationAndServiceTypeForm.controls[FormFieldTypes.BuildingName].value;
    const selectedJobSite = this.jobSiteList.filter(x => x.lotNumber === value && x.buildingName === selectedBuildingName)[0];
    this.applyDDLJobSiteChange(selectedJobSite.name);
  }

  applyDDLJobSiteChange(value: string) {
    const selectedJobSite = this.jobSiteList.filter(x => x.name === value)[0];
    if (selectedJobSite) {

      if (this.activeFormFields[FormFieldTypes.Region]) {
        this.locationAndServiceTypeForm.controls[FormFieldTypes.Region].setValue(selectedJobSite.state);
      }

      if (this.activeFormFields[FormFieldTypes.Country]) {
        this.locationAndServiceTypeForm.controls[FormFieldTypes.Country].setValue(selectedJobSite.country);
      }

      if (this.activeFormFields[FormFieldTypes.Brand]) {
        this.locationAndServiceTypeForm.controls[FormFieldTypes.Brand].setValue(selectedJobSite.brand);
      }

      if (this.activeFormFields[FormFieldTypes.ClubType]) {
        this.locationAndServiceTypeForm.controls[FormFieldTypes.ClubType].setValue(selectedJobSite.clubType);
      }


      // pre-populate phone number and email fields based on job site contact info
      this.contactDetailsForm.controls['phoneNumber'].setValue(selectedJobSite.storePhone);
      this.contactDetailsForm.controls['email'].setValue(selectedJobSite.email);
      this.contactDetailsForm.controls['confirmEmail'].setValue(selectedJobSite.email);
    }
  }

  /*
    ====================
    JOB COMPILATION AND SUBMISSION
    ====================
  */

  getSupervisorEmails(jobSite: IJobSite | undefined): string {
    if (jobSite && this.sendsSupervisorEmail) {
      return jobSite.supervisorEmails;
    } else if (this.clientID == this.clients.EasyLink) {
      // MM - for when we're using the manual address entry form, but still want to test infield supervisor alerts with our development setup
      return "mmeskell@johnslyng.com.au;jgill@johnslyng.com.au;ajohn@johnslyng.com.au;sbertolin@johnslyng.com.au";
    }
    return "";
  }

  getTooltipString(): string {
    // check which forms haven't been completed, and compose message for the user
    var tooltip = '';
    var businessNameMaximumLength = 100;
    var businessName = '';

    if (this.validate()) {
      tooltip = 'Ready to Submit!'
    } else {
      tooltip = 'Please check you have filled in all fields for step(s): ';
      if (this.locationAndServiceTypeForm.invalid) { tooltip += '1, '; }
      if ((this.usesPriorityNotDateTime && this.selectedPriority == this.priorityTypes.NoPriority) || (!this.usesPriorityNotDateTime && this.dateTimeForm.invalid)) { tooltip += '2, '; }
      if ((this.contactDetailsForm.invalid)) { tooltip += '3'; }
      if (this.enforcedImageAttachment && !this.imagesUploaded()) {
        if (this.contactDetailsForm.invalid) {
          tooltip += ', At least one image is required';
        } else {
          tooltip += 'At least one image is required';
        }
      }
      if (environment.usesBusinessName) {
        businessName = this.locationAndServiceTypeForm.value[FormFieldTypes.BusinessName];
        if (businessName != '') {
          if (businessName.length > businessNameMaximumLength) {
            tooltip += ', Business Name can not be more than 100 characters';
          }
        }
      }

      if (tooltip.endsWith(', ')) { tooltip = tooltip.substring(0, tooltip.length - 2); }
      tooltip += '.';
    }
    return tooltip;
  }

  validate(): boolean {

    var isValid = true;

    /* Additional Validation to check if at least one image is selected based on client ID  
       Discard the other validation if no images are uploaded  S.R - 06/01/2023  */

    if (this.enforcedImageAttachment && !this.imagesUploaded()) {
      isValid = false;
    } else {
      // check whether images are still uploading
      var imagesDoneProcessing = true;
      this.bookingPhotos.forEach(photo => {
        if (photo.loaded == false) {
          imagesDoneProcessing = false;
        }
      });
      isValid = imagesDoneProcessing

      // check whether a selection has been made from all enabled DDLs 
      isValid = isValid && this.locationAndServiceTypeForm.valid;

      if (this.usesManualAddressEntryForm) {
        // if we aren't using a job site DDL, check to make sure the individual address fields have been loaded via the autocomplete-address-form-component
        isValid = isValid && this.addressForm.valid;
      }

      // check whether an appointment date/time OR priority have been selected
      if (this.usesPriorityNotDateTime) {
        isValid = isValid && this.selectedPriority != this.priorityTypes.NoPriority;
      } else {
        isValid = isValid && this.dateTimeForm.valid;
      }

      // check whether contact details and problem description have been filled in
      isValid = isValid && this.contactDetailsForm.valid;
    }
    return isValid;
  }

  // Helper function to see if images are required S.R - 06/01/2023
  imagesUploaded(): boolean {
    var atLeastOneImage = false;

    // Checks to see if at least one is selected
    if (this.enforcedImageAttachment) {
      atLeastOneImage = this.bookingPhotos.length > 0;
    }
    return atLeastOneImage
  }


  compileJobData(): JobData | null {

    var jobData: JobData = new JobData();
    jobData.customerReference = '';
    jobData.externalJobID = '';
    jobData.jobReference = '';

    // var jobSite: IJobSite | undefined;
    if (this.usesManualAddressEntryForm) {

      jobData.streetUnit = this.addressForm.value.unit ? this.addressForm.value.unit : '';
      jobData.streetAddress = this.addressForm.value.streetAddress!;
      jobData.suburbName = this.addressForm.value.suburb!;
      jobData.postcode = this.addressForm.value.postcode!;

      var businessName = this.locationAndServiceTypeForm.value[FormFieldTypes.BusinessName];

      // include unit number in street address string if there is one, since EasyLink doesn't need it seperately.
      if (jobData.streetUnit.length > 0) {
        jobData.streetAddress = jobData.streetUnit + '/' + jobData.streetAddress;
      }
      //MV - added businessName as prefix to address field for RRRDW client
      if (environment.usesBusinessName && jobData.streetAddress.length > 0) {
        if (businessName != '') {
          jobData.streetAddress = businessName + " | " + jobData.streetAddress;
        }
      }
    } else {
      // debugger;
      if (this.activeFormFields[FormFieldTypes.BuildingName] && this.activeFormFields[FormFieldTypes.LotNumber]) {
        // this is a strata client, so we can't fetch the job site from the job site field
        const buildingName = this.locationAndServiceTypeForm.controls[FormFieldTypes.BuildingName].value;
        const lotNumber = this.locationAndServiceTypeForm.controls[FormFieldTypes.LotNumber].value;
        this.selectedJobSite = this.jobSiteList.find(x => x.buildingName === buildingName && x.lotNumber === lotNumber);
      } else {
        // attempt to fetch store from name in job site DDL selection
        this.selectedJobSite = this.jobSiteList.find(x => x.name.toLowerCase() === this.locationAndServiceTypeForm.controls[FormFieldTypes.JobSite].value.toLowerCase());
      }

      if (!this.selectedJobSite) {
        return null;  // todo: improve failure handling
      }

      // add address info to job data
      var addressPrefix = '';
      switch (this.clientID) {
        case this.clients.TheBodyShop:
          addressPrefix = 'The Body Shop';
          break;
        case this.clients.TheBodyShopNZ:
          addressPrefix = 'The Body Shop NZ | ';
          break;
        case this.clients.GlobalRetailBrands:
          addressPrefix = this.utilityService.getBrandNameFromAbbreviation(this.selectedJobSite.brand);
          break;
        case this.clients.DerrimutGyms:
          addressPrefix = 'Derrimut Gyms';
          break;
        case this.clients.VIVA:
          addressPrefix = this.selectedJobSite.name + " |";
          break;
        case this.clients.LIDS:
          addressPrefix = this.selectedJobSite.storeCode + " " + this.selectedJobSite.name + " |";
          break;
        case this.clients.EasyLink:
          addressPrefix = this.selectedJobSite.name + " |";
      }
      jobData.streetAddress = addressPrefix + ' ' + this.selectedJobSite.address1 + ' ' + this.selectedJobSite.address2;
      jobData.postcode = this.selectedJobSite.postcode;
      jobData.suburbName = this.selectedJobSite.suburb;

    }

    // add booking window info to job data
    var bookingWindow = new JobBookingWindow();
    if (this.usesPriorityNotDateTime) {
      // date/time picker not active, substitute dummy data: 12pm, 2 weeks from today.
      var bookingTime = this.utilityService.getTimeWindow('12');
      var bookingDate = new Date();
      bookingDate.setDate(bookingDate.getDate() + 14);
      bookingWindow.date = bookingDate;
      bookingWindow.time = bookingTime;
    } else {
      // date/time picker active, attempt to parse
      if (this.dateTimeForm.valid) {
        var bookingTime = this.utilityService.getTimeWindow(this.dateTimeForm.value.appointmentTime!);
        const bookingDate = new Date(Date.parse(this.dateTimeForm.value.appointmentDate!));
        bookingWindow.date = bookingDate;
        bookingWindow.time = bookingTime;
      } else {
        return null;  // todo: handle failure
      }
    }
    jobData.bookingWindows.push(bookingWindow);

    // add contact info to job data
    if (!this.contactDetailsForm.valid) {
      return null;
    }
    jobData.description = this.contactDetailsForm.value[FormFieldTypes.ProblemDescription];
    var contact = new JobContact();
    contact.contactEmail = this.contactDetailsForm.value.email;
    contact.contactMobile = this.contactDetailsForm.value.phoneNumber
    contact.contactPhone = this.contactDetailsForm.value.phoneNumber;
    contact.contactName = this.contactDetailsForm.value.firstName + ' ' + this.contactDetailsForm.value.lastName;

    if (environment.contactOptions.secondEmail) {
      let secondEmail = this.contactDetailsForm.value.secondEmail;

      if (secondEmail != null && secondEmail.length > 0) {
        contact.contactEmail += "; " + secondEmail;
      }
    }

    jobData.customerContact = contact;
    jobData.siteContact = contact;

    // add services info to job data
    jobData.services = [];
    let selectedService = new HomeServiceItem();
    selectedService.priceMarkup = 0;
    selectedService.discount = 0;
    selectedService.estimatedDuration = 1;
    selectedService.estimatedPrice = 1;
    selectedService.externalServiceID = this.locationAndServiceTypeForm.value.serviceType;

    selectedService.triageResponses = [
      new TriageResponseItem(0,
        JobChannelType[JobChannelType.ONLINE],
        'Booking Channel', JobChannelType[JobChannelType.ONLINE]),

      new TriageResponseItem(0,
        bookingWindow.date.toDateString() + ' ' + bookingWindow.time.toString(),
        'Booking Window',
        bookingWindow.date.toDateString() + ' ' + bookingWindow.time.toString()),

      new TriageResponseItem(0,
        this.contactDetailsForm.value[FormFieldTypes.ProblemDescription],
        'Description of works to be done:',
        this.contactDetailsForm.value[FormFieldTypes.ProblemDescription],
        JobNoteTypes.JobDescription),
    ];

    if (environment.activeDDLOptions.clubType && this.selectedJobSite) {
      selectedService.triageResponses.push(
        new TriageResponseItem(0,
          this.contactDetailsForm.value[FormFieldTypes.ProblemDescription],
          'Description of Club Type: ',
          this.selectedJobSite.clubType),
      );
    }

    if (this.usesPriorityNotDateTime) {
      selectedService.triageResponses.push(new TriageResponseItem(0, this.selectedPriority.toString(), 'Selected Priority:', this.utilityService.convertPriorityToString(this.selectedPriority)));
    }

    if (environment.usesBusinessName) {
      if (businessName != '') {
        selectedService.triageResponses.push(new TriageResponseItem(0, businessName, 'Business Name:', businessName));
      }
    }

    jobData.services.push(selectedService);

    jobData.videos = [];

    if (this.bookingVideos != null && this.bookingVideos.length > 0) {
      for (let i = 0; i < this.bookingVideos.length; i++) {
        jobData.videos.push(this.bookingVideos[i].id);
      }
    }

    return jobData;

  }

  async submit() {

    this.isSubmitting = true;

    // attempt to compile all job-related info
    var job = this.compileJobData();
    var businessName = '';

    if (environment.usesBusinessName) {
      businessName = this.locationAndServiceTypeForm.value[FormFieldTypes.BusinessName];
    }

    if (!job) {
      // compile function returned null
      this.isSubmitting = false;
      return; // todo: improve failure handling
    } else {

      // load job data into service so we can fetch it on other pages
      this.jobDataService.setJobData(job);
      this.jobDataService.setProblemDescription(this.contactDetailsForm.value[FormFieldTypes.ProblemDescription]);
      this.jobDataService.setSelectedPriority(this.selectedPriority);
      this.jobDataService.setBusinessName(businessName);
    }

    // load job into firestore
    var createdJobReference = await this.dataService.createJob(job, this.bookingPhotos, this.selectedPriority, this.getSupervisorEmails(this.selectedJobSite), businessName);
    this.jobDataService.setJobReference(createdJobReference);

    // redirect to summary page
    this.isSubmitting = false;
    this.router.navigate(['/complete']);

  }

  /*
    ====================
    FORM UPDATE METHODS
    ====================
  */

  setSelectedDate(value: any) {
    this.dateTimeForm.controls['appointmentDate'].setValue(value);
  }

  setSelectedTime(value: any) {
    this.dateTimeForm.controls['appointmentTime'].setValue(value);
  }

  setSelectedPriority(value: any) {
    this.selectedPriority = value;
  }

  /*
    ====================
    BOOKING PHOTO HANDLING
    ====================
  */

  bookingPhotoSelected(e: Event) {

    let component = this;

    var target = e.target as HTMLInputElement;
    var images = target.files;

    if (!images) {
      return;
    }

    // loop through all the selected images
    for (let i = 0; i < images.length; ++i) {

      let image = images[i];
      let exists: boolean = false;

      // check whether an image with this name has already been uploaded
      component.bookingPhotos.forEach(f => {
        if (f.name == image.name) {
          exists = true;
        }
      });

      // add the booking photo to local collection
      if (!exists && component.bookingPhotos.length < 5) {
        let p: BookingPhoto = new BookingPhoto(image.name, image);
        component.bookingPhotos.push(p);
      } else {
        if (component.bookingPhotos.length >= 5) {
          component.toastr.error(`You can't choose more than 5 photos.`, 'Error');
        }
      }
    }

    // clear selection so that if we remove a previously selected image, we can select it again
    target.value = '';
  }

  bookingVideoSelected(e: Event) {
    if (this.bookingVideos.length > this.MAX_BOOKING_VIDEOS) {
      this.toastr.error(`You can't choose more than ${this.MAX_BOOKING_VIDEOS} videos.`, 'Error');
      return;
    }

    var target = e.target as HTMLInputElement;
    var videos = target.files;

    if (videos == null || videos.length < 1) {
      return;
    }

    let uploadVideos: File[] = [];

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];

      if (video.size > this.MAX_BOOKING_VIDEO_SIZE_BYTES) {
        this.toastr.error(`Skipped ${video.name}, it exceeded the max video size of 128MB.`, 'Error');
        continue;
      }

      if (this.bookingVideos.length + uploadVideos.length + 1 > this.MAX_BOOKING_VIDEOS) {
        this.toastr.error(`Skipped ${video.name}, you can only upload ${this.MAX_BOOKING_VIDEOS} videos.`, 'Error');
        continue;
      }

      uploadVideos.push(video);
    }

    for (let i = 0; i < uploadVideos.length; i++) {
      const video = uploadVideos[i];

      const formData: FormData = new FormData();
      formData.append('file', video, video.name);

      let component = this;

      component.uploadingBookingVideos = true;

      this.httpClient.post<UploadVideoResponse>(this.videoServiceURL + 'api/Video/Upload', formData)
        .subscribe({
          next: (response: UploadVideoResponse) => {
            let bookingVideo = new BookingVideo();
            bookingVideo.id = response.VideoID;
            bookingVideo.thumbnail = response.ThumbnailURL;
            bookingVideo.url = response.VideoURL;

            component.bookingVideos.push(bookingVideo);

            component.uploadingBookingVideos = false;
          },
          error: error => {
            component.toastr.error(`Error uploading ${video.name}.`, 'Error');
            console.log(error);

            component.uploadingBookingVideos = false;
          },
          complete: () => {
          }
        });
    }
  }

  public removeBookingPhoto(photo: BookingPhoto): void {
    let index: number = -1;

    // find the index of the photo to be removed based on image name
    for (let i = 0; i < this.bookingPhotos.length; i++) {
      if (this.bookingPhotos[i].name == photo.name) {
        index = i;
      }
    }

    // if an image was found, splice it out of the array
    if (index > -1) {
      this.bookingPhotos.splice(index, 1);
    }
  }

  public removeBookingVideo(video: BookingVideo) {
    const index = this.bookingVideos.indexOf(video);

    if (index == -1) {
      return;
    }

    this.bookingVideos.splice(index, 1);
  }

}

export class UploadVideoResponse {
  public VideoID: string = '';
  public ThumbnailURL: string = '';
  public VideoURL: string = '';
}

export class BookingPhoto {

  public data: Blob | null = null;
  public name: string = '';
  public base64: string = '';
  public loaded: boolean;

  constructor(_name: string, _data: File) {

    let obj = this;
    this.loaded = false;

    // set image data to a loading spinner, so it displays while compression is happening
    this.base64 = 'assets/img/loading_spinner.gif';

    // compress image
    compressAccurately(_data, 600).then((res: Blob) => {
      obj.data = res;
      obj.name = _name;

      let reader = new FileReader();

      reader.onloadend = function () {
        if (reader.result) {
          obj.base64 = reader.result.toString();
          obj.loaded = true;
        }
      }

      reader.readAsDataURL(obj.data);
    });
  }
}

export class BookingVideo {
  public id: string = '';
  public url: string = '';
  public thumbnail: string = '';
}

interface QuestionIndexPath {
  exists: boolean;
  fieldGroupIndex: number;
  questionRowIndex: number;
  questionIndex: number;
}