import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public apiURL: string = environment.webserviceURL;

  constructor(private http: HttpClient) { }


  public callAPIGetStatus(methodPath: string, params: any, callback: (reply: any) => void, errorCallback: (error: any) => void): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.apiURL + methodPath, params, httpOptions).pipe(
      map(result => {
        return result.ResultCode == 200;
      })
    );

  }

  public callAPI(methodPath: string, params: any, callback: (reply: any) => void, errorCallback: (error: any) => void) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    this.http.post<any>(this.apiURL + methodPath, params, httpOptions).pipe(
      map(reply => {
        // console.log(reply)
        return reply;
      }),
      catchError(error => {
        console.log('API Error: ' + error.message);
        throw error.message;
        //return this.handleError(error);
      })
    ).subscribe({
      next: (reply: any) => {
        callback(reply);
      },
      error: (error: any) => {
        if (errorCallback != null) {
          errorCallback(error);
        }
      }
    });
  }
  private handleError(error: HttpErrorResponse) {
    return throwError(() => new Error('An unexpected error has occurred'));
  }
}
