<div class="toolbar-container">
  <mat-toolbar style="background-color: white;">

    <a *ngIf="showLogo && !logoIsSquare" href="/">
      <img class="toolbar-image" src="assets/img/{{clientID}}/text-logo.png">
    </a>

    <a *ngIf="showLogo && logoIsSquare" style="height: 100%;" href="/">
      <img class="toolbar-image-square" src="assets/img/{{clientID}}/text-logo.png">
    </a>

    <span class="example-spacer"></span>

    <button *ngIf="showInsertDummyJobButton" [disabled]="isInsertingDummyJob" mat-button (click)="insertDummyJob(0)">
      Insert Dummy Emergency Job
    </button>

    <button *ngIf="showInsertDummyJobButton" [disabled]="isInsertingDummyJob" mat-button (click)="insertDummyJob(1)">
      Insert Dummy Medium Job
    </button>

    <button *ngIf="showInsertDummyJobButton" [disabled]="isInsertingDummyJob" mat-button (click)="insertDummyJob(2)">
      Insert Dummy Low Job
    </button>

    <button *ngIf="showLogoutButton()" mat-button (click)="logout()" data-testid="loginflow-logoutButton">
      Log Out
    </button>

  </mat-toolbar>
</div>