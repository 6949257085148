<div class="footer-container">

    <div class="footer-image-column">
        <img class="footer-image" src="../../assets/img/{{clientID}}/footer-logo.png">
    </div>

    <div class="footer-links">

        <div class="footer-column">
            <div class="footer-title">
                Help / Support
            </div>
            <div class="footer-body">
                <div *ngIf="hasServicePortal">
                    Need technical support? Our <a href={{servicePortalURL}} target="_blank">Customer Service Portal</a> is just a click away.
                </div>
                <div *ngIf="!hasServicePortal">
                    <a href="mailto:support@onetouchservices.com.au">Click here</a> to email our development team.
                </div>
            </div>
        </div>
        <div class="footer-column">
            <div class="footer-title">
                Legal
            </div>
            <div class="footer-body">
                <a href="/privacy">Privacy Policy</a>
                <br>
                <a href="/terms">Terms & Conditions</a>
            </div>
        </div>
    </div>

</div>

<div class="footer-copyright">
    © OneTouch Services, a Johns Lyng Group company. All rights reserved.
</div>