<div class="complete-container">

    <mat-card>
        <div class="complete-title">Booking Submitted!</div>
        <br>
        <div class="complete-subtitle">Job Reference:
            <span data-testid="bookingdetails-jobReference">{{getJobReference()}}</span>
        </div>
    </mat-card>

    <br>

    <mat-card>
        <div class="complete-subtitle">
            Detail Summary
        </div>
        <hr>
        <br>
        <div class="complete-detail-row-block">
            <div class="complete-detail-title">Address:</div>
            <div class="complete-detail-content">{{getCompleteAddressString()}}</div>
        </div>
        <div class="complete-detail-summary-container">
            <div class="complete-detail-summary-column">

                <div class="complete-detail-column-block">
                    <div class="complete-detail-title">Service Type:</div>
                    <div class="complete-detail-content">{{serviceType}}</div>
                </div>

                <div *ngIf="!usesPriorityNotDateTime" class="complete-detail-column-block">
                    <div class="complete-detail-title">Requested Date:</div>
                    <div class="complete-detail-content">{{shortDate}}</div>
                </div>

                <div *ngIf="!usesPriorityNotDateTime" class="complete-detail-column-block">
                    <div class="complete-detail-title">Requested Time:</div>
                    <div class="complete-detail-content">{{timeWindowString}}</div>
                </div>

                <div *ngIf="usesPriorityNotDateTime" class="complete-detail-column-block">
                    <div class="complete-detail-title">Job Priority:</div>
                    <div class="complete-detail-content">{{selectedPriority}}</div>
                </div>

            </div>

            <div class="complete-detail-summary-column">

                <div class="complete-detail-column-block">
                    <div class="complete-detail-title">Contact Name:</div>
                    <div class="complete-detail-content">{{jobData.customerContact.contactName}}</div>
                </div>

                <div class="complete-detail-column-block">
                    <div class="complete-detail-title">Contact Phone Number:</div>
                    <div class="complete-detail-content">{{jobData.customerContact.contactPhone}}</div>
                </div>

            </div>

        </div>

        <div class="complete-detail-row-block">
            <div class="complete-detail-title">Problem Description:</div>
            <div class="complete-detail-content">{{problemDescription}}</div>
        </div>
    </mat-card>

    <br>

    <mat-card>
        <div class="button-container">
            <a mat-button href="/">Return Home</a>
        </div>
    </mat-card>
</div>