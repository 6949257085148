import { Directive, HostListener, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Directive({
  selector: '[stepper-scroller]'
})
export class StepperScrollerDirective {

  @Input('orientation') orientation: string = '';

  calledOnPageLoad: boolean = false;

  constructor(private stepper: MatStepper) { }

  @HostListener('animationDone')
  selectionChanged() {
    const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
    const stepElement = document.getElementById(stepId);

    if (stepElement && this.orientation == 'vertical' && this.calledOnPageLoad) {
      stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }

    this.calledOnPageLoad = true;
  }
}
