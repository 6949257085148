import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PriorityInfoDialogComponent, DialogData } from '../priority-info-dialog/priority-info-dialog.component';
import { PriorityTypes } from '../globals.service';

@Component({
  selector: 'app-priority-picker-collapsible',
  templateUrl: './priority-picker-collapsible.component.html',
  styleUrls: ['./priority-picker-collapsible.component.css']
})
export class PriorityPickerCollapsibleComponent implements OnInit {

  @Output() priorityChangeEvent = new EventEmitter<number>();

  prioritySelection: number = this.priorityTypes.NoPriority;

  dialogData: DialogData[] = []

  constructor(public dialog: MatDialog, protected priorityTypes: PriorityTypes) { }

  ngOnInit(): void {
    this.loadDialogData();
  }

  prioritySelected(selection: number) {
    this.prioritySelection = selection;
    this.priorityChangeEvent.emit(selection);
  }

  openInfoMenu(selection: number): void {

    const selectedPriority = this.dialogData.filter(x => x.priorityType == selection)[0];
    if (selectedPriority) {
      const dialogRef = this.dialog.open(PriorityInfoDialogComponent, {
        panelClass: 'dialog-responsive',
        data: selectedPriority
      });
    }
  }

  loadDialogData() {
    // todo: load this data per client, probably in a seperate file so it can be accessed anywhere
    this.dialogData = [    
      { priorityType: this.priorityTypes.Emergency ,title: 'Emergency', subtitle: 'Issues that immediately and effectively threaten safety and store operation.', points: ['Life threatening & safety issues.', 'Emergency Health or Safety risk to employees or patrons.', 'Unable to perform everyday duties or trade.', 'Fire alarms.', 'Gas leaks or smell of gas.', 'Exposed power or total power loss.', 'Loss of water to entire building or flood/overflowing plumbing.'] },
      { priorityType: this.priorityTypes.MediumPriority ,title: 'Medium Priority', subtitle: 'Issues that do not pose immediate danger to people or property.', points: ['No immediate effect to duties or trade.', 'Minimal impact to service.', 'Minor leaks.', 'Power loss.', 'HVAC loss.', 'Unable to lock & secure the building.', 'Loss of lighting at night, etc.'] },
      { priorityType: this.priorityTypes.LowPriority ,title: 'Low Priority', subtitle: 'Issues that don’t pose a safety risk, don’t damage the property, and don’t prevent the store from trading.', points: ['No loss of income as result.', 'No safety concerns.', 'Installing shelving.', 'Replacing light bulbs.', 'Hanging pictures.', 'Painting.', 'Issues in the BOH.', 'Non-emergency repairs.'] }
    ]
  }
}
