import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  constructor() { }
}

export class TradeTypes {
  readonly PlumbingAndGas: number = 1;
  readonly Electrical: number = 2;
  readonly Glazing: number = 3;
  readonly Carpentry: number = 4;
  readonly GeneralTrade: number = 5;
  readonly HeatingAndCooling: number = 6;
  readonly RoofPlumbing: number = 7;
  readonly Fencing: number = 8;
}

@Injectable({
  providedIn: 'root'
})
export class Clients {
  readonly Demo: number = 0;
  readonly EasyLink: number = 278;  // aka: GHR Direct
  readonly GlobalRetailBrands: number = 3893;
  readonly AHA: number = 4684;
  readonly TheBodyShop = 5319;
  readonly DerrimutGyms = 5219;
  readonly LIDS = 5498;
  readonly VIVA: number = 5159;
  readonly OneTouchDirect: number = 3355;
  readonly RRRDW: number = 5597;
  readonly TheBodyShopNZ = 5679;

  constructor() { }
}

export enum JobChannelType {
  ONLINE = 1,
  ADMIN = 2,
  TMC = 3,
  CALLCENTRE = 4,
}

// 2023-07-11 - MM - this enum maps to: EasyLink\EasyLinkGlobals\Enums\JobNote.vb, and the [MenuEHAJobNoteType] table
// it only contains specific note types that we might need to add from the front end, no need for most of the note types here
export enum JobNoteTypes {
  JobDescription = 51
}

@Injectable({
  providedIn: 'root'
})
export class PriorityTypes {
  readonly NoPriority: number = -1;
  readonly Emergency: number = 0;
  readonly MediumPriority: number = 1;
  readonly LowPriority: number = 2;
}


export enum FormFieldTypes {
  None = '',

  Country = 'country',
  Brand = 'brand',
  Region = 'region',
  JobSite = 'jobSite',
  ServiceType = 'serviceType',
  ClubType = 'clubType',
  BusinessName = 'businessName',
  ProblemDescription = 'problemDescription',

  LotNumber = 'lotNumber',
  BuildingName = 'buildingName'
}

export enum CustomValidationTypes {
  Email = 'email',
  businessNameMaxLength = 'maxlength'
}