import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PriorityTypes } from '../globals.service';

export interface DialogData {
  priorityType: number;
  title: string;
  subtitle: string;
  points: string[];
}

@Component({
  selector: 'app-priority-info-dialog',
  templateUrl: './priority-info-dialog.component.html',
  styleUrls: ['./priority-info-dialog.component.css']
})
export class PriorityInfoDialogComponent implements OnInit {

  priorityType: number = 0;
  title: string = '';
  subtitle: string = '';
  points: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PriorityInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected priorityTypes: PriorityTypes) { }

  ngOnInit(): void {
    this.priorityType = this.data.priorityType;
    this.title = this.data.title;
    this.subtitle = this.data.subtitle;
    this.points = this.data.points;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

}
