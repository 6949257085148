import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobChannelType } from '../app/globals.service';
import { JobData } from './job-data.service';

import {
  CollectionReference,
  DocumentData,
  collection,
  doc,
  setDoc,
  runTransaction,
} from '@firebase/firestore';

import { collectionData, Firestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { BookingPhoto } from './triage-dynamic/triage-dynamic.component';

interface Booking {
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreDataService {

  constructor(private readonly db: Firestore) {
  }

  async createJob(jobData: JobData, bookingPhotos: Array<BookingPhoto>, priorityType: number, supervisorEmails: string, businessName: string): Promise<string> {

    let concurrencyRef = doc(this.db, 'concurrency/jobref');
    var createdJobReference: string = '';

    try {
      await runTransaction(this.db, async (transaction) => {

        // get the last used jobref from the tracker
        var jobrefDoc = await transaction.get(concurrencyRef);

        if (!jobrefDoc.exists()) {
          throw 'Could not find job ref tracking document.';
        }

        // increment the last used jobref
        const newJobRef = jobrefDoc.data()['lastProcessed'] + 1;

        // generate document name
        const id = environment.jobPrefix + '_' + newJobRef.toString();

        // add jobref and job data to booking blob
        let newBooking: any = {};
        newBooking.jobref = newJobRef;
        newBooking.data = JSON.stringify(jobData);

        // add processing metadata to booking blob
        newBooking.processed = false;
        newBooking.processedDate = null;
        newBooking.insertedDate = new Date();
        newBooking.tries = 0;

        // add data carried over from WL-Classic to bookinb blob (not sure if needed)
        newBooking.bookingType = 1; // 1 = scheduled, 2 = HIP, 3 = EHA
        newBooking.channel = JobChannelType.ONLINE;
        newBooking.marketingOptIn = false;
        newBooking.offerCode = '';
        newBooking.whereDidYouHearAboutUs = '';
        newBooking.estimatedPrice = 1;
        newBooking.estimatedDuration = 1;

        // add priority type
        newBooking.priorityType = priorityType;

        // add supervisor email addresses
        newBooking.supervisorEmails = supervisorEmails;

        // add businessName
        newBooking.businessName = businessName;

        // create booking document in firestore
        transaction.set(doc(this.db, 'bookings', id), newBooking);

        // loop over booking photos
        const component = this;
        let photoIndex: number = 0;
        bookingPhotos.forEach(p => {

          let newPhoto: any = {};

          // add image data to image blob
          newPhoto.jobref = newJobRef;
          newPhoto.name = p.name;
          newPhoto.data = p.base64;

          // add processing metadata to image blob
          newPhoto.processed = false;
          newPhoto.tries = 0;
          newPhoto.processedDate = null;

          // generate document id
          let photoID = id + '_' + photoIndex;

          // create booking photo document in firestore
          transaction.set(doc(component.db, 'bookingphotos', photoID), newPhoto);

          // increment id tracker
          photoIndex += 1;

        });

        // increment the jobref tracker document
        transaction.update(concurrencyRef, { lastProcessed: newJobRef });
        createdJobReference = newJobRef.toString();

      });

    } catch (e) {
      // todo: handle gracefully
      console.log(e);
    }

    return createdJobReference;
  }


  fetchStores(): Observable<StoresList[]> {

    var storesCollection: CollectionReference<DocumentData>;
    storesCollection = collection(this.db, 'stores');
    return collectionData(storesCollection) as Observable<StoresList[]>;

  }

}

export interface IJobSite {

  // job site metadata
  name: string;             // todo: rename to siteName
  storePhone: string;       // todo: rename to siteContactPhone
  email: string;            // todo: rename to siteContactEmail
  supervisorEmails: string;

  // job site address
  address1: string;
  address2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;

  // retail-specific fields
  storeCode: number;
  clubType: string;
  brand: string;

  // strata-specific fields
  buildingName: string;
  lotNumber: string;
}


export interface StoresList {
  data: string;
}