export const environment = {
  production: false,
  jobPrefix: 'ER',
  clientID: 5498,
  logoConfig: {
    showLogoInToolbar: true,
    logoIsSquare: false,
  },
  usesPriorityNotDateTime: true,
  useConvertToTitleCase: false,
  loginConfig: {
    isPasswordProtected: true,
    authenticatesViaWebservice: true,
  },
  enforcedImageAttachment: true,
  sendsSupervisorEmailForEmergencyJobs: true,
  webserviceURL: "https://easylinkapi.johnslyng.com.au/",
  videoServiceURL: 'https://easylinkvideoserviceapi.johnslyng.com.au/',
  loadsStoreDataFromFirebase: true,
  mapsConfig: {
    usesMapsAPI: false,
    googleMapsCountries: [],
    isBoundToRegion: true
  },
  activeDDLOptions: {
    country: false,
    region: true,
    jobSite: true,
    serviceType: true,
    brand: false,
    clubType: false
  },
  contactOptions: {
    secondEmail: false,
  },
  servicePortal: {
    hasServicePortal: false,
    servicePortalURL: ""
  },
  // webserviceURL: "http://localhost:51843/",
  firebase: {
    projectId: 'lids-wl-live',
    appId: '1:252586282323:web:a1921f2b3e60abb3ee25a6',
    storageBucket: 'lids-wl-live.appspot.com',
    apiKey: 'AIzaSyA6ya0s7RG21CeJunMkUN7u1bJKEsDKVhU',
    authDomain: 'lids-wl-live.firebaseapp.com',
    messagingSenderId: '252586282323'
  },
  usesBusinessName: false,
  showInsertDummyJobButton: false
};