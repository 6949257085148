import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PriorityTypes } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class JobDataService {

  public jobData = new JobData();
  public problemDescription: string = '';
  public selectedPriority: number = this.priorityTypes.NoPriority;
  public businessName: string = '';
  public jobReference: string = '';

  constructor(public datePipe: DatePipe, public priorityTypes: PriorityTypes) { }

  public setJobData(_jobData: JobData) {
    this.jobData = _jobData;
  }

  public getJobData(): JobData {
    return this.jobData;
  }

  public setAddressData(unit: string, streetAddress: string, suburb: string, postcode: string) {
    this.jobData.postcode = postcode;
    this.jobData.streetUnit = unit;
    this.jobData.streetAddress = streetAddress;
    this.jobData.suburbName = suburb;
  }

  public setBusinessName(_businessname: string) {
    this.businessName = _businessname;
  }

  public setProblemDescription(_description: string) {
    this.problemDescription = _description;
  }

  public getProblemDescription(): string {
    return this.problemDescription;
  }

  public setSelectedPriority(_selectedPriority: number) {
    this.selectedPriority = _selectedPriority;
  }

  public getSelectedPriority(): number {
    return this.selectedPriority;
  }

  public setJobReference(jobReference: string): void {
    this.jobReference = jobReference;
  }

  public getShortDate(): string {
    let shortDate = this.datePipe.transform(this.jobData.bookingWindows[0].date, 'dd/MM/yyyy');
    if (shortDate) {
      return shortDate;
    }
    return '';
  }

  public getTimeWindowString(): string {
    let windowString = this.jobData.bookingWindows[0].time?.toString();
    if (windowString) {
      return windowString;
    }
    return '';
  }
}

export class JobData {

  public bookingWindows: Array<JobBookingWindow> = [];
  public customerContact: JobContact = new JobContact();
  public customerReference: string = '';
  public description: string = '';
  public externalJobID: string = '';
  public jobReference: string = '';
  public postcode: string = '';
  public services: Array<HomeServiceItem> = [];
  public siteContact: JobContact = new JobContact();
  public streetAddress: string = '';
  public streetUnit: string = '';
  public suburbName: string = '';
  public estimatedPrice: number = 0;
  public estimatedHours: number = 0;
  public videos: string[] = [];

  constructor() { }
}

export class JobBookingWindow {
  public date: Date | null = null;
  public time: JobTimeWindow | null = null;
}

export class JobTimeWindow {
  public from: string = '';
  public to: string = '';

  public toString = (): string => {
    return this.from + ' - ' + this.to;
  }
}

export class JobContact {

  public contactEmail: string = '';
  public contactMobile: string = '';
  public contactName: string = '';
  public contactPhone: string = '';

  constructor() { }

}

export class HomeServiceItem {

  public externalServiceID: string = '';
  public priceMarkup: number = 0;
  public discount: number = 0;
  public estimatedPrice: number = 0;
  public estimatedDuration: number = 0;
  public triageResponses: Array<TriageResponseItem> = [];

  constructor() { }

}

export class TriageResponseItem {

  public questionTypeID: number = 0;
  public noteTypeID: number = -1;
  public triageAnswer: string = '';
  public triageFullAnswer: string = '';
  public triageQuestion: string = '';
  public showOnSOW: boolean = false;
  public inclusionData: InclusionData | null = null;

  constructor(_typeID: number, _answer: string, _question: string, _fullAnswer: string, _noteTypeID: number = -1, _showOnSOW: boolean = false) {
    this.questionTypeID = _typeID;
    this.noteTypeID = _noteTypeID;
    this.triageAnswer = _answer;
    this.triageQuestion = _question;
    this.triageFullAnswer = _fullAnswer;
    this.showOnSOW = _showOnSOW;
  }

}

export class InclusionData {
  public header: string = '';
  public sections: Array<InclusionSection> = [];
}

export class InclusionSection {
  public question: string = '';
  public answer: string = '';
}
