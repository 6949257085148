<div class="triage-stepper-container">
    <mat-stepper stepper-scroller="stepperOrientation" [orientation]="(stepperOrientation | async)!">

        <!-- FORM STEP 1 -->
        <mat-step [stepControl]="locationAndServiceTypeForm" label="What/Where">
            <mat-card>
                <div *ngFor="let fieldGroup of locationAndServiceTypeFieldGroups">
                    <div class="triage-prompt">{{fieldGroup.prompt}}</div>
                    <div *ngFor="let questionRow of fieldGroup.questionRows" class="form-item-container">
                        <div *ngFor="let question of questionRow" class="form-item">
                            <app-question 
                            [question]="question"
                            [form]="locationAndServiceTypeForm"
                            (ddlSelectionChangeEvent)="applyDDLSelectionChange(question.key, $event)"></app-question>
                        </div>
                    </div>
                </div>
                <div *ngIf="usesManualAddressEntryForm">
                    <hr>
                    <app-autocomplete-address-form [addressForm]="addressForm"></app-autocomplete-address-form>
                </div>
            </mat-card>
            <div class="button-container">
                <button mat-button matStepperNext style="float:right;" data-testid="bookingdetails-nextButton-whatWhere">Next</button>
            </div>
        </mat-step>

        <!-- FORM STEP 2 -->
        <mat-step [stepControl]="dateTimeForm" label="{{whenTitle}}">
            <form [formGroup]="dateTimeForm">
                <div class="form-container">
                    <mat-card>
                        <app-priority-picker-collapsible *ngIf="usesPriorityNotDateTime" (priorityChangeEvent)="setSelectedPriority($event)"></app-priority-picker-collapsible>
                        <app-datetime-picker *ngIf="!usesPriorityNotDateTime" (dateChangeEvent)="setSelectedDate($event)" (timeChangeEvent)="setSelectedTime($event)"></app-datetime-picker>
                    </mat-card>
                </div>
            </form>
            <div class="button-container">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext style="float:right;" data-testid="bookingdetails-nextButton-priority">Next</button>
            </div>
        </mat-step>

        <!-- FORM STEP 3 -->
        <mat-step [stepControl]="contactDetailsForm" label="Who/Why">
            <form [formGroup]="contactDetailsForm">
                <mat-card>
                    <div class="form-container">
                        <div *ngFor="let fieldGroup of contactDetailsFieldGroups">
                            <div class="triage-prompt">{{fieldGroup.prompt}}</div>
                            <div *ngFor="let questionRow of fieldGroup.questionRows" class="form-item-container">
                                <div *ngFor="let question of questionRow" class="form-item">
                                    <app-question [question]="question" [form]="contactDetailsForm"></app-question>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div style="text-align: center;">
                            <div class="triage-prompt">
                                <label for="workRequired">Attach up to 5 photos of the works required (at least 1 photo is required):</label>
                            </div>
                            <br>
                            <div class="file-upload">
                                <input type="file" style="display: none;" accept="image/png, image/jpeg" multiple
                                    (change)="bookingPhotoSelected($event)" #photoUpload data-testid="bookingdetails-fileUploadPhoto" />
                                <button mat-button class="upload-btn" (click)="photoUpload.click()" data-testid="bookingdetails-uploadPhotoButton">Choose files</button>
                            </div>

                            <div class="bookingPhotoContainer">
                                <div class="bookingPhoto" *ngFor="let photo of bookingPhotos; let i=index">
                                    <img attr.data-testID="bookingdetails-photoThumbnail-{{i}}" src="{{ photo.base64 }}" />
                                    <button mat-button class="remove"
                                        (click)="removeBookingPhoto(photo)">Remove</button>
                                </div>
                            </div>

                        </div>
                        <div style="text-align: center;">
                            <div class="triage-prompt">
                                <label for="workRequired">Attach up to 3 videos of the works required (up to 30 seconds or {{MAX_BOOKING_VIDEO_SIZE_MB}}MB per video):</label>
                            </div>
                            <br>
                            <div class="file-upload" *ngIf="!uploadingBookingVideos">
                                <input type="file" style="display: none;" accept="video/mp4, video/quicktime" multiple
                                    (change)="bookingVideoSelected($event)" #videoUpload />
                                <button mat-button class="upload-btn" (click)="videoUpload.click()" data-testid="bookingdetails-uploadVideoButton">Choose files</button>
                            </div>

                            <div class="file-upload" *ngIf="uploadingBookingVideos">
                                <img src="assets/img/loading_spinner.gif" />
                            </div>

                            <div class="bookingPhotoContainer">
                                <div class="bookingPhoto" *ngFor="let video of bookingVideos">
                                    <img src="{{ video.thumbnail }}" />
                                    <button mat-button class="remove"
                                        (click)="removeBookingVideo(video)">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </form>
            <div class="button-container">
                <button mat-button matStepperPrevious>Back</button>
                <button type="submit" [disabled]="!validate() || isSubmitting" mat-button style="float:right;" data-testid="bookingdetails-submitButton"
                    (click)="submit()">
                    <div [matTooltip]="getTooltipString()">Submit</div>
                </button>
            </div>
        </mat-step>
    </mat-stepper>
</div>
