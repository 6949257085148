import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { map, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  clientID: number = environment.clientID;
  isPasswordProtected: boolean = environment.loginConfig.isPasswordProtected;
  authenticatesViaWebservice: boolean = environment.loginConfig.authenticatesViaWebservice;

  constructor(private apiService: ApiService, private http: HttpClient) { }

  // this function just checks if an item exists in localStorage called token or sessionID, and authenticates if either exist
  checkLoginRelaxed(): boolean {
    var token = localStorage.getItem('token');
    var sessionID = localStorage.getItem('sessionID');
  
    if (token || sessionID) {
      return true;
    }

    return false;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('sessionID');
  }

  login(clientID: string, password: string, redirect: (success: boolean, error: string) => void) {
    
    // compose request object
    var dtoObj: any = {};
    dtoObj.Username = clientID;
    dtoObj.Password = password;

    // JSONify request object
    const blob = JSON.stringify(dtoObj);

    this.apiService.callAPI("rpcapi/Account/LoginWLLite", blob, async reply => {
      
      if (reply.ResultCode == 200) {

        // login successful
        console.log('Login successful.');
        
        // store auth credentials in local storage
        localStorage.setItem('token', reply.Token);
        localStorage.setItem('sessionID', reply.SessionID);

        // callback function redirects to home page
        redirect(true, "");

      } else {
        // login failed
        redirect(false, "Error: Login failed. Please check your password and try again.");
      }
    },
    async error => {
      // handle error
      console.log('ERROR: ' + error);
      redirect(false, "Error: Could not reach authorisation server. Please try again shortly.");
    });
  }

  authenticate() {

    var sessionID = localStorage.getItem('sessionID');
    var token = localStorage.getItem('token');

    // compose request object
    var dtoObj: any = {};
    dtoObj.SessionID = sessionID;
    dtoObj.Token = token;
    dtoObj.ClientID = this.clientID;

    // JSONify request
    const blob = JSON.stringify(dtoObj);

    this.apiService.callAPI("rpcapi/Account/AuthenticateWLLite", blob, async reply => {
   
      if (reply.ResultCode == 200) {

        // authentication successful, store token and sessionID.
        localStorage.setItem('token', reply.Token);
        localStorage.setItem('sessionID', reply.SessionID);

      } else {

        // authentication failed, clear local storage
        localStorage.removeItem('token');
        localStorage.removeItem('sessionID');
      }
    
    },
    async error => {
      // handle error
      console.log('ERROR: ' + error);
    });
  }

  // testing function
  // only checks that token/sessionID exist in local storage, does not communicate with back-end
  authStatusTemp(): Observable<boolean> {
    var sessionID = localStorage.getItem('sessionID');
    var token = localStorage.getItem('token');

    // auth fails if no session ID or token found
    if (!sessionID || !token) {
      return of(false);
    }

    // auth fails if session ID or token are empty 
    if (sessionID == '' || token == '') {
      return of(false);
    }

    return of(true);
  }

  authStatus(): Observable<boolean> {

    if (!this.isPasswordProtected) {
      return of(true);
    }

    // if webservice is not set up for this client, authenticate locally
    if (!this.authenticatesViaWebservice) {
      return this.authStatusTemp();
    }

    var sessionID = localStorage.getItem('sessionID');
    var token = localStorage.getItem('token');

    // auth fails if no session ID or token found
    if (!sessionID || !token) {
      return of(false);
    }

    // auth fails if session ID or token are empty 
    if (sessionID == '' || token == '') {
      return of(false);
    }

    // set up request object
    var dtoObj: any = {};
    dtoObj.SessionID = sessionID;
    dtoObj.Token = token;
    dtoObj.ClientID = this.clientID;
    
    // JSONify request object
    const blob = JSON.stringify(dtoObj);

    // ping the request, return a boolean observable (true if status == 200)
    return this.apiService.callAPIGetStatus("rpcapi/Account/AuthenticateWLLite", blob, () => {}, () => {});

  }
}
