import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Clients } from '../globals.service';

import { QuestionService } from '../question.service';
import { Observable } from 'rxjs';

import { InputFieldGroup } from '../question.service';
import { ActivatedRoute } from '@angular/router';

interface ServiceType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private defaultEmail: string = '';

  contactDetailsFieldGroups$: Observable<InputFieldGroup[]>;
  locationAndServiceTypeFieldGroups$: Observable<InputFieldGroup[]>;

  clientID = environment.clientID;


  constructor(
    public clients: Clients,
    private questionService: QuestionService,
    private route: ActivatedRoute) {

    this.contactDetailsFieldGroups$ = questionService.getContactDetailQuestions();
    this.locationAndServiceTypeFieldGroups$ = questionService.getJobSiteAndServiceTypeQuestionsByClient(this.clientID);

  }

  ngOnInit(): void {
    this.route.queryParams // get querystring params
      .subscribe(params => {
        const email = params['email'] || '';
        const email2 = params['email2'] || '';

        this.questionService.emailQuestion.value = email;
        this.questionService.confirmEmailQuestion.value = email;

        if (environment.contactOptions.secondEmail) {
          this.questionService.secondEmailQuestion.value = email2;
        }
      });
  }

}
