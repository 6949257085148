<div class="info-container" [ngClass]="{
    'info-container-emergency' : priorityType == priorityTypes.Emergency,
    'info-container-medium' : priorityType == priorityTypes.MediumPriority,
    'info-container-low' : priorityType == priorityTypes.LowPriority
 }">

    <div class="info-title">
        {{title}}
    </div>
    <div class="info-subtitle">
        {{subtitle}}
        <!-- Issues that don’t pose a safety risk, don’t damage the property, and don’t prevent the store from trading. -->
    </div>
    <div class="info-description">
        <ul *ngFor="let point of points">
            <li>{{point}}</li>
            <!-- <li>No loss of income as result</li>
            <li>No safety concerns</li>
            <li>Installing shelving</li>
            <li>Replacing light bulbs</li>
            <li>Hanging pictures</li>
            <li>Painting </li>
            <li>Issues in the BOH</li>
            <li>Non-emergency repairs</li> -->
        </ul>
    </div>

</div>
<div class="button-container">
    <button mat-button (click)="close()">Return</button>
</div>
