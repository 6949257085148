import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  password = new FormControl('');
  clientID: number = environment.clientID;
  authenticatesViaWebservice: boolean = environment.loginConfig.authenticatesViaWebservice;

  loginErrorMessage: string = '';
  showLoginErrorMessage: boolean = false;

  loginButtonDisabled: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router) { }


  ngOnInit(): void {
  }

  submit() {
    if (this.authenticatesViaWebservice) {
      this.submitPassword();
    } else {
      this.submitPasswordTemp();
    }
  }

  // this method requires no communication with the webservice, and should be used for offline testing only
  submitPasswordTemp() {

    if (this.password.value == 'test') {
      localStorage.setItem('token', 'testToken');
      localStorage.setItem('sessionID', 'testSession');
      this.router.navigate(['/']);
    } else {
      this.showLoginErrorMessage = true;
    }
  }

  submitPassword() {

    // do nothing if password contains no content
    if (!this.password.value)
      return;

    this.loginButtonDisabled = true;

    // attempt to log in, and redirect user on success
    this.authService.login(this.clientID.toString(), this.password.value, this.redirect);

  }

  // callback function to handle redirect after successful login
  private redirect = (success: boolean, error: string) => {

    if (success) {
      this.showLoginErrorMessage = false;
      this.loginErrorMessage = '';
      this.router.navigate(['/']);
    } else {
      this.loginErrorMessage = error;
      this.showLoginErrorMessage = true;
    }

    this.loginButtonDisabled = false;
  }

}
